import { type Periods, type PeriodTime } from '../../features/shared/period-state'
import { PeriodType } from '../../features/shared/period-type'

export const startOfDay = (now: Date): Date => new Date(now.setHours(0, 0, 0, 0))

const getDateWithDaysOffset = (date: Date, numDays: number): Date => {
  const dayBefore = new Date(date)
  dayBefore.setDate(date.getDate() + numDays)
  return dayBefore
}

export const theDayBefore = (date: Date): Date => getDateWithDaysOffset(date, -1)
export const theDayAfter = (date: Date): Date => getDateWithDaysOffset(date, 1)

const sevenDaysBefore = (date: Date): Date => getDateWithDaysOffset(date, -7)
export const thirtyDaysBefore = (date: Date): Date => getDateWithDaysOffset(date, -30)

const firstOfTheMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), 1)
const lastOfTheMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() + 1, 0)
const firstOfPreviousMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() - 1, 1)

const firstOfTheQuarter = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() - (date.getMonth() % 3), 1)
const lastOfTheQuarter = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() - (date.getMonth() % 3) + 3, 0)
const firstOfPreviousQuarter = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() - (date.getMonth() % 3) - 3, 1)

const firstOfTheYear = (date: Date): Date => new Date(date.getFullYear(), 0, 1)
const lastOfTheYear = (date: Date): Date => new Date(date.getFullYear(), 11, 31)
const firstOfPreviousYear = (date: Date): Date => new Date(date.getFullYear() - 1, 0, 1)

export const isToday = (date: Date): boolean => {
  const today = new Date()
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
}

export const gtDate = (date1: Date, date2: Date): boolean => {
  return date1.toISOString().slice(0, 10) > date2.toISOString().slice(0, 10)
}

export const ltDate = (date1: Date, date2: Date): boolean => {
  return date1.toISOString().slice(0, 10) < date2.toISOString().slice(0, 10)
}

export const eqDate = (date1: Date, date2: Date): boolean => {
  return date1.toISOString().slice(0, 10) === date2.toISOString().slice(0, 10)
}

export const formatDateToTimeAMPM = (date: Date): string => {
  let hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours %= 12
  hours = hours || 12
  const strHours = hours < 10 ? '0' + hours : hours
  const strMinutes = minutes < 10 ? '0' + minutes : minutes
  return strHours + ':' + strMinutes + ' ' + ampm
}

export const getPeriodsByType = (period: PeriodType, today = new Date()): Periods => {
  const yesterday = theDayBefore(today)
  const tomorrow = theDayAfter(today)
  const lastMonth = firstOfPreviousMonth(today)
  const lastQuarter = firstOfPreviousQuarter(today)
  const lastYear = firstOfPreviousYear(today)
  const sevenDaysAgo = sevenDaysBefore(tomorrow)
  const thirtyDaysAgo = thirtyDaysBefore(tomorrow)

  switch (period) {
    case PeriodType.Today:
      return {
        current: DatePeriodFactory(today, tomorrow),
        prev: DatePeriodFactory(yesterday, today)
      }
    case PeriodType.Yesterday:
      return {
        current: DatePeriodFactory(yesterday, today),
        prev: DatePeriodFactory(theDayBefore(yesterday), yesterday)
      }
    case PeriodType.Last7Days:
      return {
        current: DatePeriodFactory(sevenDaysAgo, tomorrow),
        prev: DatePeriodFactory(sevenDaysBefore(sevenDaysAgo), sevenDaysAgo)
      }
    case PeriodType.Last30Days:
      return {
        current: DatePeriodFactory(thirtyDaysAgo, tomorrow),
        prev: DatePeriodFactory(thirtyDaysBefore(thirtyDaysAgo), thirtyDaysAgo)
      }
    case PeriodType.CurrentMonth: {
      const firstOfThisMonth = firstOfTheMonth(today)
      const diff = daysDiff(firstOfThisMonth, theDayAfter(lastOfTheMonth(today)))
      return {
        current: DatePeriodFactory(firstOfThisMonth, theDayAfter(lastOfTheMonth(today))),
        prev: DatePeriodFactory(getDateWithDaysOffset(firstOfThisMonth, -diff), firstOfThisMonth)
      }
    }
    case PeriodType.MonthToDate: {
      const firstOfThisMonth = firstOfTheMonth(today)
      const diff = daysDiff(firstOfThisMonth, tomorrow)
      return {
        current: DatePeriodFactory(firstOfThisMonth, tomorrow),
        prev: DatePeriodFactory(lastMonth, getDateWithDaysOffset(lastMonth, diff))
      }
    }
    case PeriodType.LastMonth: {
      const diff = daysDiff(lastMonth, firstOfTheMonth(today))
      return {
        current: DatePeriodFactory(lastMonth, firstOfTheMonth(today)),
        prev: DatePeriodFactory(getDateWithDaysOffset(lastMonth, -diff), lastMonth)
      }
    }
    case PeriodType.CurrentQuarter: {
      const firstOfThisQuarter = firstOfTheQuarter(today)
      const diff = daysDiff(firstOfThisQuarter, theDayAfter(lastOfTheQuarter(today)))
      return {
        current: DatePeriodFactory(firstOfThisQuarter, theDayAfter(lastOfTheQuarter(today))),
        prev: DatePeriodFactory(getDateWithDaysOffset(firstOfThisQuarter, -diff), firstOfThisQuarter)
      }
    }
    case PeriodType.QuarterToDate: {
      const firstOfThisQuarter = firstOfTheQuarter(today)
      const diff = daysDiff(firstOfThisQuarter, tomorrow)
      return {
        current: DatePeriodFactory(firstOfThisQuarter, tomorrow),
        prev: DatePeriodFactory(lastQuarter, getDateWithDaysOffset(lastQuarter, diff))
      }
    }
    case PeriodType.LastQuarter: {
      const diff = daysDiff(lastQuarter, firstOfTheQuarter(today))
      return {
        current: DatePeriodFactory(lastQuarter, firstOfTheQuarter(today)),
        prev: DatePeriodFactory(getDateWithDaysOffset(lastQuarter, -diff), lastQuarter)
      }
    }
    case PeriodType.CurrentYear:
      return {
        current: DatePeriodFactory(firstOfTheYear(today), theDayAfter(lastOfTheYear(today))),
        prev: DatePeriodFactory(lastYear, firstOfTheYear(today))
      }
    case PeriodType.YearToDate: {
      const firstOfThisYear = firstOfTheYear(today)
      const diff = daysDiff(firstOfThisYear, tomorrow)
      return {
        current: DatePeriodFactory(firstOfThisYear, tomorrow),
        prev: DatePeriodFactory(lastYear, getDateWithDaysOffset(lastYear, diff))
      }
    }
    case PeriodType.LastYear:
      return {
        current: DatePeriodFactory(lastYear, firstOfTheYear(today)),
        prev: DatePeriodFactory(firstOfPreviousYear(lastYear), lastYear)
      }

    default:
      throw new Error('Invalid period type')
  }
}

export const getPeriodsByDates = (from: Date, to: Date): Periods => {
  const current = DatePeriodFactory(from, theDayAfter(to))
  const startPeriod = startOfDay(from)
  const endPeriod = startOfDay(theDayAfter(to))
  const prev = DatePeriodFactory(getDateWithDaysOffset(startPeriod, -daysDiff(startPeriod, endPeriod)), startPeriod)

  return {
    current,
    prev
  }
}

const DatePeriodFactory = (firstDay: Date, lastDay: Date): PeriodTime => {
  const StartCurrentPeriod = Math.floor(startOfDay(firstDay).getTime() / 1000)
  const EndCurrentPeriod = Math.floor(startOfDay(lastDay).getTime() / 1000)

  return {
    from: StartCurrentPeriod,
    to: EndCurrentPeriod
  }
}

export const formatDateToMonthYear = (date: Date, locale = 'en-US'): string => {
  const d = new Date(date)
  const monthName = d.toLocaleString(locale, { month: 'short' })
  const year = d.getFullYear()

  return `${monthName} ${year}`
}

export const formatDateToUTCMonthYear = (date: Date, locale = 'en-US'): string => {
  const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
  const monthName = utcDate.toLocaleString(locale, { month: 'short', timeZone: 'UTC' })
  const year = utcDate.getUTCFullYear()

  return `${monthName} ${year}`
}

export const getDateFormatParts = (): string[] => {
  const formatObj = new Intl.DateTimeFormat(
    navigator.language,
    { year: 'numeric', month: '2-digit', day: '2-digit' }
  ).formatToParts(new Date())

  return formatObj.map(({ type }) => type).filter(type => type !== 'literal')
}

export const parseLocalDateString = (dateString: string): Date => {
  const dateParts = dateString.split(/[^0-9]/).map(part => parseInt(part, 10))
  const dateObj: Record<string, number> = {}

  getDateFormatParts().forEach((part, index) => {
    if (part === 'year') {
      dateObj.year = dateParts[index]
    } else if (part === 'month') {
      dateObj.month = dateParts[index]
    } else if (part === 'day') {
      dateObj.day = dateParts[index]
    }
  })

  if (dateObj.year !== undefined && dateObj.month !== undefined && dateObj.day !== undefined) {
    return new Date(dateObj.year, dateObj.month - 1, dateObj.day)
  } else {
    throw new Error('Invalid date format or date string')
  }
}

export const getDateFormatString = (): string => {
  const formatParts = getDateFormatParts()
  const sampleDate = new Date().toLocaleDateString()
  const separators = sampleDate.match(/[^a-zA-Z0-9]/g) ?? ['/']

  const formatMap: Record<string, string> = {
    day: 'dd',
    month: 'mm',
    year: 'yyyy'
  }

  return formatParts
    .map(part => formatMap[part])
    .join(separators[0])
}

export const formatDateToRegular = (date: Date, locale = 'en-US'): string => {
  const d = new Date(date)
  return d.toLocaleString(locale, { year: 'numeric', month: 'long', day: 'numeric' })
}

export const daysDiff = (date: Date, date2: Date): number => {
  const DayInSeconds = 24 * 60 * 60 * 1000
  return Math.floor((date2.getTime() - date.getTime()) / DayInSeconds)
}

export const formatMinutesToTimezone = (offset: number): string => {
  const isNegative = offset > 0
  const absoluteOffset = Math.abs(offset)
  const hours = String(Math.floor(absoluteOffset / 60)).padStart(2, '0')
  const minutes = String(absoluteOffset % 60).padStart(2, '0')
  return `${isNegative ? '-' : '+'}${hours}:${minutes}`
}
