import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import Dialog from '../shared/components/Dialog/Dialog'
import DialogActions from '../shared/components/Dialog/DialogActions'
import Arrow from '../../assets/images/arrow.svg'
import Line from '../../assets/images/linethrough.svg'
import { t } from 'i18next'
import { type MainPlans } from '../../features/payment/domain/models/offering'
import { useNumberFormatter } from '../../shared/helpers/currency-formatter'

export interface OptOutPeriodDialogProps {
  readonly onActionAccept: (hasAccepted: boolean) => void
  readonly offering: MainPlans
}

const OptOutPeriodDialog: Component<OptOutPeriodDialogProps> = (props) => {
  const { currencyFormatter } = useNumberFormatter()

  const starterDiscount = (): string | undefined => currencyFormatter(props.offering.starter.monthly.price * 12 - props.offering.starter.annual.price)
  const proDiscount = (): string | undefined => currencyFormatter(props.offering.pro.monthly.price * 12 - props.offering.pro.annual.price)

  return (
    <Dialog contentFit rounded>
      <div class="flex flex-col gap-8 p-8">
        <span class="text-4xl font-semibold text-center" innerHTML={t('ls_opt_out_title')} />
        <div class="flex flex-col gap-2">
          <span class="text-lg font-seminbold text-center">
            <Trans key={'ls_opt_out_description_starter'} options={{ discount: starterDiscount() }}/>
          </span>
          <div class="flex gap-6 text-center justify-center">
            <div class="flex flex-col gap-2">
              <span class="text-gray-500 text-lg">
                <Trans key="ls_generic_monthly" />
              </span>
              <div class="flex flex-col">
                <div class="relative">
                  <span class="text-4xl font-semibold">
                    { currencyFormatter(props.offering.starter.monthly.price * 12) }
                  </span>
                  <img src={Line} class="absolute top-4 w-full"/>
                </div>
                <span class="text-gray-500 text-sm">
                  <Trans key="ls_generic_per_year" />
                </span>
              </div>
            </div>
            <div class="arrow h-full relative min-w-[62px]">
              <img src={Arrow} class="absolute top-10"/>
            </div>
            <div class="flex flex-col gap-2">
              <span class="text-gray-500 text-lg">
                <Trans key="ls_generic_annual"/>
              </span>
              <div class="flex flex-col">
                <span class="text-4xl font-semibold">
                  { currencyFormatter(props.offering.starter.annual.price) }
                </span>
                <span class="text-gray-500 text-sm">
                  <Trans key="ls_generic_per_year" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <span class="text-lg font-seminbold text-center">
            <Trans key={'ls_opt_out_description_pro'} options={{ discount: proDiscount() }} />
          </span>
          <div class="flex gap-6 text-center justify-center">
            <div class="flex flex-col gap-2">
              <span class="text-gray-500 text-lg">
                <Trans key="ls_generic_monthly"/>
              </span>
              <div class="flex flex-col">
                <div class="relative">
                  <span class="text-4xl font-semibold">
                    { currencyFormatter(props.offering.pro.monthly.price * 12) }
                  </span>
                  <img src={Line} class="absolute top-4 w-full"/>
                </div>
                <span class="text-gray-500 text-sm">
                  <Trans key="ls_generic_per_year" />
                </span>
              </div>
            </div>
            <div class="arrow h-full relative min-w-[62px]">
              <img src={Arrow} class="absolute top-10"/>
            </div>
            <div class="flex flex-col gap-2">
              <span class="text-gray-500 text-lg">
                <Trans key="ls_generic_annual"/>
              </span>
              <div class="flex flex-col">
                <span class="text-4xl font-semibold">
                  { currencyFormatter(props.offering.pro.annual.price) }
                </span>
                <span class="text-gray-500 text-sm">
                  <Trans key="ls_generic_per_year" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogActions>
        <button
          onClick={() => {
            props.onActionAccept(false)
          }}
          class="btn btn--white">
          <Trans key="ls_opt_out_cancel"/>
        </button>
        <button
          onClick={() => {
            props.onActionAccept(true)
          }}
          class="btn btn--primary">
          <Trans key="ls_opt_out_accept"/>
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default OptOutPeriodDialog
