import { t } from 'i18next'
import type { Component } from 'solid-js'
import MiniInformationCircle from '../../../assets/heroicons/MiniInformationCircle'
import { type Position } from '../../../shared/helpers/dom.functions'

export interface TableColumnTooltipProps {
  readonly columnId: string
  readonly position?: Position
}

const TableColumnTooltip: Component<TableColumnTooltipProps> = (props) => {
  return (
    <div
      class="flex flex-col gap-2 border-[1px] border-gray-200 w-[236px] text-gray-900 text-sm absolute bg-white p-4 shadow-lg rounded-lg break-words z-50"
      style={{
        left: `${props.position?.left ?? 0}px`,
        top: `${props.position?.top ?? 24}px`
      }}>
      <div class="flex gap-2 font-bold">
        <span class="text-gray-400"><MiniInformationCircle/></span>
        {t(`ls_table_col_${props.columnId}`)}
      </div>
      <span class="font-normal">{t(`ls_table_col_hint_${props.columnId}_hint`)}</span>
    </div>
  )
}

export default TableColumnTooltip
