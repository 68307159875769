import { Cached } from '@coris-ts/helpers/utilities'
import { type ApiRequestService } from '@coris-ts/repository/data-source/api-request.service'
import {
  provideDefaultArrayNetworkDataSource,
  provideDefaultNetworkDataSource
} from '@coris-ts/repository/data-source/network.data-source'
import { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import { ArrayMapper, VoidMapper } from '@coris-ts/repository/mapper/mapper'
import { GetRepositoryMapper, RepositoryMapper } from '@coris-ts/repository/repository-mapper'
import {
  SingleDataSourceRepository,
  SingleGetDataSourceRepository
} from '@coris-ts/repository/single-data-source.repository'
import {
  provideVoidNetworkDataSourceRepository
} from '@coris-ts/repository/single-void-data-source.repository'
import { CreditEntity } from './data/entities/credit.entity'
import { OfferingEntity } from './data/entities/offering.entity'
import { PurchasesEntity } from './data/entities/purchases.entity'
import { SubscriptionChangeEntity } from './data/entities/subscription-change.entity'
import { SubscriptionEntity } from './data/entities/subscription.entity'
import { CancelSubscriptionInteractor } from './domain/interactors/cancel-subscription.interactor'
import { GetAvailableCreditInteractor } from './domain/interactors/get-available-credit.interactor'
import { GetMainPlansInteractor } from './domain/interactors/get-main-plans.interactor'
import { GetOfferingInteractor } from './domain/interactors/get-offering.interactor'
import { GetPurchasesInteractor } from './domain/interactors/get-purchases.interactor'
import {
  PreviewSubscriptionChangeInteractor
} from './domain/interactors/preview-subscription-change.interactor'
import { PurchaseProductInteractor } from './domain/interactors/purchase-product.interactor'
import {
  ReactivateSubscriptionInteractor
} from './domain/interactors/reactivate-subscription.interactor'
import {
  UpdateCheckoutStatusInteractor
} from './domain/interactors/update-checkout-status.interactor'
import {
  UpdateSubscriptionInteractor
} from './domain/interactors/update-subscription.interactor'
import { CreditToCreditEntityMapper } from './domain/mappers/credit.mappers'
import { OfferingEntityToMainPlansMapper } from './domain/mappers/main-plans.mappers'
import {
  OfferingEntityToOfferingMapper,
  OfferingProductEntityToOfferingProductMapper
} from './domain/mappers/offering.mappers'
import {
  OneTimePurchaseEntityToOneTimePurchaseMapper
} from './domain/mappers/one-time-purchase.mappers'
import { PurchaseProductEntityToPurchaseProductMapper } from './domain/mappers/purchase-offering.mappers copy'
import { PurchasesEntityToPurchasesMapper } from './domain/mappers/purchases.mappers'
import { SubscriptionChangeEntityToSubscriptionChangeMapper } from './domain/mappers/subscription-change.mappers'
import { SubscriptionEntityToSubscriptionMapper } from './domain/mappers/subscription.mappers'
import { type Credit } from './domain/models/credit'
import { type MainPlans, type Offering } from './domain/models/offering'
import { type Purchases } from './domain/models/purchases'
import { type Subscription } from './domain/models/subscription'
import { type SubscriptionChange } from './domain/models/subscription-change'

export interface PaymentComponent {
  provideCancelSubscription: () => CancelSubscriptionInteractor
  provideGetOffering: () => GetOfferingInteractor
  provideGetMainPlans: () => GetMainPlansInteractor
  providePurchaseProduct: () => PurchaseProductInteractor
  provideUpdateSubscription: () => UpdateSubscriptionInteractor
  provideGetPurchases: () => GetPurchasesInteractor
  provideReactivateSubscription: () => ReactivateSubscriptionInteractor
  provideUpdateCheckoutStatus: () => UpdateCheckoutStatusInteractor
  providePreviewSubscriptionChange: () => PreviewSubscriptionChangeInteractor
  provideGetAvailableCredit: () => GetAvailableCreditInteractor
}

export class PaymentDefaultModule implements PaymentComponent {
  constructor (private readonly apiRequestService: ApiRequestService) {
  }

  @Cached()
  private getMainPlansRepository(): GetRepositoryMapper<OfferingEntity, MainPlans> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      OfferingEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new OfferingEntityToMainPlansMapper()
    )
  }

  @Cached()
  private getOfferingRepository(): GetRepositoryMapper<OfferingEntity, Offering> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      OfferingEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new OfferingEntityToOfferingMapper()
    )
  }

  @Cached()
  private getSubscriptionRepository(): RepositoryMapper<SubscriptionEntity, Subscription> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      SubscriptionEntity
    )

    const repository = new SingleDataSourceRepository(dataSource, dataSource, dataSource)

    return new RepositoryMapper(
      repository,
      repository,
      repository,
      new SubscriptionEntityToSubscriptionMapper(new OfferingProductEntityToOfferingProductMapper()),
      new VoidMapper()
    )
  }

  @Cached()
  private getPurchasesRepository(): RepositoryMapper<PurchasesEntity, Purchases> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PurchasesEntity
    )

    const repository = new SingleDataSourceRepository(dataSource, dataSource, dataSource)

    const offeringProductMapper = new OfferingProductEntityToOfferingProductMapper()
    const purchaseProductMapper = new PurchaseProductEntityToPurchaseProductMapper()
    return new RepositoryMapper(
      repository,
      repository,
      repository,
      new PurchasesEntityToPurchasesMapper(
        new OneTimePurchaseEntityToOneTimePurchaseMapper(purchaseProductMapper),
        new SubscriptionEntityToSubscriptionMapper(offeringProductMapper)
      ),
      new VoidMapper()
    )
  }

  @Cached()
  private getSubscriptionChangeRepository(): GetRepositoryMapper<SubscriptionChangeEntity, SubscriptionChange> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      SubscriptionChangeEntity
    )

    return new GetRepositoryMapper(
      new SingleGetDataSourceRepository(dataSource),
      new SubscriptionChangeEntityToSubscriptionChangeMapper()
    )
  }

  @Cached()
  private getCreditRepository(): GetRepositoryMapper<CreditEntity[], Credit[]> {
    const dataSource = provideDefaultArrayNetworkDataSource(
      this.apiRequestService,
      CreditEntity
    )

    return new GetRepositoryMapper(
      new SingleGetDataSourceRepository(dataSource),
      new ArrayMapper(
        new CreditToCreditEntityMapper()
      )
    )
  }

  public provideGetMainPlans (): GetMainPlansInteractor {
    return new GetMainPlansInteractor(
      new GetInteractor<MainPlans>(this.getMainPlansRepository())
    )
  }

  public provideGetOffering (): GetOfferingInteractor {
    return new GetOfferingInteractor(
      new GetInteractor<Offering>(this.getOfferingRepository())
    )
  }

  public provideGetPurchases(): GetPurchasesInteractor {
    return new GetPurchasesInteractor(
      new GetInteractor<Purchases>(this.getPurchasesRepository())
    )
  }

  public provideCancelSubscription(): CancelSubscriptionInteractor {
    return new CancelSubscriptionInteractor(
      new PutInteractor<Subscription>(this.getSubscriptionRepository())
    )
  }

  public provideReactivateSubscription(): ReactivateSubscriptionInteractor {
    return new ReactivateSubscriptionInteractor(
      new PutInteractor<Subscription>(this.getSubscriptionRepository())
    )
  }

  public provideUpdateCheckoutStatus(): UpdateCheckoutStatusInteractor {
    return new UpdateCheckoutStatusInteractor(
      new PutInteractor<void>(provideVoidNetworkDataSourceRepository(this.apiRequestService))
    )
  }

  public provideUpdateSubscription(): UpdateSubscriptionInteractor {
    return new UpdateSubscriptionInteractor(
      new PutInteractor<Subscription>(this.getSubscriptionRepository())
    )
  }

  public providePreviewSubscriptionChange(): PreviewSubscriptionChangeInteractor {
    return new PreviewSubscriptionChangeInteractor(
      new GetInteractor<SubscriptionChange>(this.getSubscriptionChangeRepository())
    )
  }

  public provideGetAvailableCredit(): GetAvailableCreditInteractor {
    return new GetAvailableCreditInteractor(
      new GetInteractor<Credit[]>(this.getCreditRepository())
    )
  }

  public providePurchaseProduct(): PurchaseProductInteractor {
    return new PurchaseProductInteractor(
      new PutInteractor<Subscription>(this.getSubscriptionRepository())
    )
  }
}
