import { Trans } from '@mbarzda/solid-i18next'
import { useLocation, useParams } from '@solidjs/router'
import { Show, createEffect, createSignal, type Component } from 'solid-js'
import logoAnimation from '../../../assets/animations/logo-animated.json'
import { KPIType, type KPI } from '../../../features/analytics/domain/models/kpi'
import { MainModule } from '../../../features/main-module'
import { type SKUProductReport } from '../../../features/product/domain/models/product'
import { type PeriodsState } from '../../../features/shared/period-state'
import { KPIGroupId } from '../../../shared/models/kpi-group'
import { useError } from '../../../shared/providers/error.provider'
import { usePeriods } from '../../../shared/providers/period.provider'
import { useShop } from '../../../shared/providers/shop.provider'
import SkuSummaryBox from '../../cogs/SkuSummaryBox'
import { AnalyticsConfig } from '../../shared/analytics.vm'
import AnalyticsChart from '../../shared/components/AnalyticsChart'
import LottieAnimation from '../../shared/components/LottieAnimation'
import PerUnitMainStats from '../../shared/components/PerUnitMainStats'
import StatsGroup from '../../shared/components/StatsGroup'
import { getKPIVMsInGroup } from '../../shared/functions/kpi.functions'
import { executeWithUrlScope } from '../../shared/functions/url-scoped-promise'
import ProductListingsTable from './ProductListingsTable'
import ProductMissingCogsBanner from './ProductMissingCogsBanner'

const Product: Component = () => {
  const [selectedKpiType, setSelectedKpiType] = createSignal(KPIType.OperatingProfit)
  const [selectedKpi, setSelectedKpi] = createSignal<KPI>()
  const [kpis, setKpis] = createSignal<KPI[]>([])
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [product, setProduct] = createSignal<SKUProductReport>()

  const { selectedShop } = useShop()
  const { periods } = usePeriods()
  const { setError } = useError()

  const analyticsComponent = MainModule.getAnalyticsComponent()
  const getProduct = analyticsComponent.provideGetProductReport()
  const getTimeseries = analyticsComponent.provideGetProductAnalyticsTimeseries()

  const location = useLocation()

  createEffect(() => {
    const shopId = selectedShop()?.id
    if (!shopId) return

    const params = useParams()
    reloadProduct(shopId, +params.productId, periods())
  })

  const reloadProduct = (shopId: number, productId: number, period: PeriodsState): void => {
    setIsLoading(true)
    executeWithUrlScope(
      async () => {
        await getProduct.execute(shopId, productId, period)
          .then((product) => {
            setProduct(product)
            setKpis([
              product.unitsSold,
              product.roi,
              product.opExpenses,
              product.opProfit,
              product.profitRatio,
              product.cogsKPI,
              product.netSales
            ])
          })
      }, location
    )
      .catch((error) => {
        console.error(error)
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  createEffect((): void => {
    setSelectedKpi(
      kpis().find((KPI) => KPI.id === selectedKpiType())!
    )
  })

  return (
    <>
      <Show when={selectedShop()?.isFullyFetched && product()?.missingCogs}>
        <ProductMissingCogsBanner productId={product()!.product.id}/>
      </Show>
      <h1 class="mb-4 page-title"><Trans key="ls_product_title"/></h1>
      <div class="flex flex-col gap-9">
        <SkuSummaryBox product={product()?.product} numListings={1}/>
        <div class="grid gap-9 content-center">
          <PerUnitMainStats kpis={getKPIVMsInGroup(kpis(), KPIGroupId.Main)}
                            selectedKPI={selectedKpiType}
                            setSelectedKPI={setSelectedKpiType} units={product()?.unitsSold.value}/>
          <Show when={!isLoading() && selectedKpi()}>{(kpi) =>
            <AnalyticsChart kpi={kpi()} getTimeseriesAction={getTimeseries}
                            entityId={product()?.product.id}/>
          }</Show>
          <Show when={isLoading() || !selectedKpi()}>
            <div
              class="w-full h-[421px] rounded-lg bg-white flex items-center justify-center shadow">
              <LottieAnimation animationData={logoAnimation} width="2rem"/>
            </div>
          </Show>
          <div class="sm:hidden">
            <StatsGroup config={AnalyticsConfig.performance!}
                        kpis={getKPIVMsInGroup(kpis(), KPIGroupId.Performance)} color="green"
                        selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>
          </div>
          <div class="hidden sm:block">
            <StatsGroup direction={'row'} config={AnalyticsConfig.performance!}
                        kpis={getKPIVMsInGroup(kpis(), KPIGroupId.Performance)} color="green"
                        selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>
          </div>
        </div>

        <ProductListingsTable product={product()}/>
      </div>
    </>
  )
}

export default Product
