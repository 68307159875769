import type { Component } from 'solid-js'
import { createEffect, createSignal } from 'solid-js'
import { clickOutside } from '../../shared/directives/click-outside'
import styles from './Sidebar.module.scss'
import SidebarContent from './SidebarContent'

interface SidebarProps {
  isOpen: boolean
  onSidebarClose: () => void
}

enum SidebarState {
  Opening,
  Open,
  Closing,
  Closed
}

const Sidebar: Component<SidebarProps> = (props) => {
  const [sidebarState, setSidebarState] = createSignal(SidebarState.Closed)

  let timeoutId: NodeJS.Timeout | undefined

  createEffect(() => {
    if (props.isOpen) {
      setSidebarState(SidebarState.Opening)
      timeoutId = setTimeout(() => {
        setSidebarState(SidebarState.Open)
      }, 10)
    } else {
      timeoutId = setTimeout(() => {
        setSidebarState(SidebarState.Closed)
      }, 300)
      setSidebarState(SidebarState.Closing)
    }

    return () => {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId)
      }
    }
  })

  const handleSidebarClose = (): void => {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId)
    }
    props.onSidebarClose()
  }

  return (
      <>
        <div class={`${sidebarState() === SidebarState.Closed ? 'hidden' : 'visible'}` + ' relative z-50 lg:hidden'} role="dialog" aria-modal="true">
          <div class={`${styles.opacityTransition} ${sidebarState() === SidebarState.Open ? 'opacity-100' : 'opacity-0'}` + ' fixed inset-0 bg-gray-900/80'} />

          <div class="fixed inset-0 flex">
            <div
              class={`relative mr-16 flex w-full max-w-xs flex-1 ${styles.sidebarMb} ${sidebarState() === SidebarState.Open ? styles.sidebarMbOpen : styles.sidebarMbClosed}`}
              ref={el => {
                clickOutside(el, handleSidebarClose)
              }}>
              <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button onClick={handleSidebarClose} class={`${styles.opacityTransition} ${sidebarState() === SidebarState.Open ? 'opacity-100' : 'opacity-0'} -m-2.5 p-2.5`} type="button">
                  <span class="sr-only">Close sidebar</span>
                  <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <SidebarContent onItemSelected={handleSidebarClose}/>
            </div>
          </div>
        </div>

        <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <SidebarContent onItemSelected={handleSidebarClose}/>
        </div>
      </>
  )
}

export default Sidebar
