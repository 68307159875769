import { Trans } from '@mbarzda/solid-i18next'
import { For, Show, createEffect, createSignal, onMount, type Component } from 'solid-js'
import MiniChevronDown from '../../assets/heroicons/MiniChevronDown'
import { mapTimestampToDate } from '../../features/shared/map-timestamp-to-date'
import { PeriodType, SelectablePeriods } from '../../features/shared/period-type'
import { clickOutsideCalendar } from '../../shared/directives/click-outside-calendar'
import { useDateFormatter } from '../../shared/helpers/date-formatter'
import { getPeriodsByDates, getPeriodsByType, theDayBefore } from '../../shared/helpers/date.functions'
import { usePeriods } from '../../shared/providers/period.provider'
import { useTracking } from '../../shared/providers/tracking.provider'
import CustomPeriodSelector from './CustomPeriodSelector'

enum SelectState {
  Opening,
  Open,
  Closing,
  Closed
}

const PeriodSelector: Component = () => {
  const [selectState, setSelectState] = createSignal(SelectState.Closed)
  const [isCustomPeriodSelected, setIsCustomPeriodSelected] = createSignal(false)
  const [selectedPeriod, setSelectedPeriod] = createSignal<PeriodType | 'custom'>(PeriodType.Last7Days)
  const { periods, setPeriods } = usePeriods()
  const { shortDateFormatter } = useDateFormatter()
  const { trackEvent } = useTracking()

  onMount(() => {
    setSelectedPeriod(periods().type)
  })

  const setCustomPeriod = (): void => {
    setIsCustomPeriodSelected(true)
  }

  const openSelector = (): void => {
    if (selectState() !== SelectState.Open && selectState() !== SelectState.Opening) {
      setSelectState(SelectState.Opening)
      setTimeout(() => {
        setSelectState(SelectState.Open)
      }, 10)
    }
  }

  const closeSelector = (): void => {
    setIsCustomPeriodSelected(false)
    if (selectState() !== SelectState.Closed && selectState() !== SelectState.Closing) {
      setSelectState(SelectState.Closing)
      setTimeout(() => {
        setSelectState(SelectState.Closed)
      }, 300)
    }
  }

  const onActionOptionSelected = (period: PeriodType): void => {
    trackEvent('Time Period', { period })
    setSelectedPeriod(period)
    closeSelector()
  }

  const toggleMenu = (): void => {
    if (selectState() === SelectState.Open) {
      closeSelector()
    }
    if (selectState() === SelectState.Closed) {
      openSelector()
    }
  }

  const onCustomPeriodSelected = (from: Date, to: Date): void => {
    setPeriods({
      type: PeriodType.Custom,
      ...getPeriodsByDates(from, to)
    })
    setSelectedPeriod('custom')
    closeSelector()
  }

  createEffect(() => {
    const period = selectedPeriod()
    if (period !== 'custom') {
      setPeriods({
        type: period,
        ...getPeriodsByType(period)
      })
    }
  })

  const isOneDayPeriod = (): boolean => {
    return selectedPeriod() === PeriodType.Today || selectedPeriod() === PeriodType.Yesterday
  }

  return (
    <>
      <div ref={el => { clickOutsideCalendar(el, closeSelector) } }>
        <div class="relative w-56 pl-3 py-1.5">
          <button onClick={toggleMenu} type="button" class="w-fit relative cursor-pointer rounded-md bg-white pr-10 text-left text-gray-900 focus:outline-none sm:text-sm sm:leading-6" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
            <span class="block truncate text-lg font-semibold"><Trans key={`ls_period_${selectedPeriod()}`} /></span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <MiniChevronDown />
            </span>
          </button>

          <div class="text-sm text-gray-500 font-light">
            <span>{shortDateFormatter(mapTimestampToDate(periods().current.from)!)}</span>
            <Show when={!isOneDayPeriod()}>
              <span> - {shortDateFormatter(theDayBefore(mapTimestampToDate(periods().current.to)!))}</span>
            </Show>
          </div>
          <div>
            <div class={`${selectState() === SelectState.Closed ? 'hidden' : 'visible'} ${selectState() === SelectState.Open ? ' w-100 opacity-100 ' : ' opacity-0'}` + ' absolute z-10 mt-1 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'} tabindex="-1" role="listbox" aria-labelledby="listbox-label">
              <Show when={!isCustomPeriodSelected()} fallback={
                <CustomPeriodSelector close={() => setIsCustomPeriodSelected(false)} submit={ onCustomPeriodSelected } />
              }>
                <ul role="listbox" aria-labelledby="listbox-label" class="w-64">
                  <For each={SelectablePeriods}>{(period, index) => (
                    <>
                      <li onClick={() => { onActionOptionSelected(period) }}
                          class="bg-white hover:bg-gray-100 transition duration-300 text-gray-900 h-9 relative select-none py-2 pl-3 pr-9 cursor-pointer" id="listbox-option-0" role="option">
                        <span class="block truncate" classList={{ 'font-bold': selectedPeriod() === period }}>
                          <Trans key={`ls_period_${period}`} />
                        </span>
                      </li>
                      <Show when={index() % 2 === 1}>
                        <div class="w-full h-px bg-gray-200 my-1" />
                      </Show>
                    </>
                  )}</For>
                  <li onClick={ setCustomPeriod }
                      class="bg-white hover:bg-gray-100 transition duration-300 text-gray-900 h-9 relative select-none py-2 pl-3 pr-9 cursor-pointer" id="listbox-option-0" role="option">
                    <span class="block truncate" classList={{ 'font-bold': selectedPeriod() === 'custom' }}>
                      <Trans key={'ls_period_custom'} />
                    </span>
                  </li>
                </ul>
              </Show>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PeriodSelector
