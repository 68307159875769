import { PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { Cached } from '@coris-ts/helpers/utilities'
import { type ApiRequestService } from '@coris-ts/repository/data-source/api-request.service'
import {
  provideDefaultArrayNetworkDataSource,
  provideDefaultNetworkDataSource
} from '@coris-ts/repository/data-source/network.data-source'
import { DeleteInteractor } from '@coris-ts/repository/interactor/delete.interactor'
import { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { PutInteractor } from '@coris-ts/repository/interactor/put.interactor'
import {
  ArrayMapper,
  PaginationOffsetLimitMapper,
  VoidMapper
} from '@coris-ts/repository/mapper/mapper'
import { type GetRepository, type Repository } from '@coris-ts/repository/repository'
import { GetRepositoryMapper, PutRepositoryMapper } from '@coris-ts/repository/repository-mapper'
import {
  SingleGetDataSourceRepository,
  SinglePutDataSourceRepository
} from '@coris-ts/repository/single-data-source.repository'
import {
  provideVoidNetworkDataSourceRepository
} from '@coris-ts/repository/single-void-data-source.repository'
import { BulkTaskIdEntity } from './data/entities/bulk-task-id.entity'
import { CogsUpdateStatusEntity } from './data/entities/cogs-update-status.entity'
import { type ListingReportEntity } from './data/entities/listing.entity'
import { type OrderEntity } from './data/entities/order.entity'
import { ProductCogsEntity } from './data/entities/product-cogs.entity'
import { type ProductCostsWithUnitsEntity } from './data/entities/product-costs-with-units.entity'
import { type ProductCostsEntity } from './data/entities/product-costs.entity'
import {
  type ProductEntity,
  type ProductReportEntity,
  type SKUProductReportEntity
} from './data/entities/product.entity'
import { CreateCogsPeriodInteractor } from './domain/interactors/create-cogs-period.interactor'
import { GetCatalogInteractor } from './domain/interactors/get-catalog.interactor'
import { GetCogsIssuesInteractor } from './domain/interactors/get-cogs-issues.interactor'
import { GetCogsUpdateStatusInteractor } from './domain/interactors/get-cogs-update-status.interactor'
import { GetListingReportsInteractor } from './domain/interactors/get-listing-reports.interactor'
import { GetOrdersInteractor } from './domain/interactors/get-orders.interactor'
import { GetProductCogsInteractor } from './domain/interactors/get-product-cogs.interactor'
import { GetCatalogFiltersInteractor } from './domain/interactors/get-product-costs-filters.interactor'
import { GetProductProductsInteractor } from './domain/interactors/get-product-products.interactor'
import { GetProductReportsInteractor } from './domain/interactors/get-product-reports.interactor'
import { GetSkuProductReportsInteractor } from './domain/interactors/get-sku-product-reports.interactor'
import { MarkCogsUpdateAsViewedInteractor } from './domain/interactors/mark-cogs-update-as-viewed.interactor'
import { ReconcileCogsInteractor } from './domain/interactors/reconcile-cogs.interactor'
import { UpdateProductCogsHistoricInteractor } from './domain/interactors/update-product-cogs-historic.interactor'
import { UploadCogsInBulkInteractor } from './domain/interactors/upload-cogs-in-bulk.interactor'
import {
  CogsUpdateStatusEntityToCogsUpdateStatusMapper
} from './domain/mappers/cogs-update-status.mappers'
import { CogsEntityToCogsMapper, CogsTypeEntityToCogsTypeMapper } from './domain/mappers/cogs.mappers'
import { ListingReportEntityToListingReportMapper } from './domain/mappers/listing-reports.mappers'
import { ListingEntityToListingMapper } from './domain/mappers/listing.mappers'
import { OrderEntityToOrderMapper } from './domain/mappers/order.mappers'
import { ProductCogsEntityToProductCogsMapper } from './domain/mappers/product-cogs.mappers'
import {
  ProductCostsWithUnitsEntityToProductCostsWithUnitsMapper
} from './domain/mappers/product-costs-with-units.mappers'
import { ProductCostsEntityToProductCostsMapper } from './domain/mappers/product-costs.mappers'
import { ProductReportEntityToProductReportMapper } from './domain/mappers/product-report.mappers'
import { ProductEntityToProductMapper } from './domain/mappers/product.mappers'
import {
  SKUProductReportEntityToSKUProductReportMapper
} from './domain/mappers/sku-product-reports.mappers'
import { BulkTaskIdToStringMapper } from './domain/mappers/task-id.mappers'
import { type CogsUpdateStatus } from './domain/models/cogs-update-status'
import { type ListingReport } from './domain/models/listing'
import { type Order } from './domain/models/order'
import { type Product, type ProductReport, type SKUProductReport } from './domain/models/product'
import { type ProductCogs } from './domain/models/product-cogs'
import { type ProductCost } from './domain/models/product-cost'
import { type ProductCostsWithUnits } from './domain/models/product-costs-with-units'

export interface ProductComponent {
  provideCreateCogsPeriod: () => CreateCogsPeriodInteractor
  provideGetListingReports: () => GetListingReportsInteractor
  provideGetOrders: () => GetOrdersInteractor
  provideGetProductCogs: () => GetProductCogsInteractor
  provideGetCatalog: () => GetCatalogInteractor
  provideGetCogsIssues: () => GetCogsIssuesInteractor
  provideGetCatalogFilters: () => GetCatalogFiltersInteractor
  provideGetSkuProductReports: () => GetSkuProductReportsInteractor
  provideGetProductReports: () => GetProductReportsInteractor
  provideReconcileCogs: () => ReconcileCogsInteractor
  provideUpdateProductCogsHistoricInteractor: () => UpdateProductCogsHistoricInteractor
  provideUploadCogsInBulk: () => UploadCogsInBulkInteractor
  provideGetCogsUpdateStatus: () => GetCogsUpdateStatusInteractor
  provideGetProductProducts: () => GetProductProductsInteractor
  provideMarkCogsUpdateAsViewed: () => MarkCogsUpdateAsViewedInteractor
}

export class ProductDefaultModule implements ProductComponent {
  private readonly productMapper = new ProductEntityToProductMapper(
    new ListingEntityToListingMapper(),
    new CogsEntityToCogsMapper(),
    new CogsTypeEntityToCogsTypeMapper()
  )

  constructor (private readonly apiRequestService: ApiRequestService) {
  }

  @Cached()
  private getListingReportsRepository(): GetRepositoryMapper<PaginationOffsetLimit<ListingReportEntity>, PaginationOffsetLimit<ListingReport>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<ListingReportEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new ListingReportEntityToListingReportMapper()
      )
    )
  }

  @Cached()
  private getOrdersRepository(): GetRepositoryMapper<PaginationOffsetLimit<OrderEntity>, PaginationOffsetLimit<Order>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<OrderEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new OrderEntityToOrderMapper()
      )
    )
  }

  @Cached()
  private getCatalogRepository(): GetRepositoryMapper<PaginationOffsetLimit<ProductCostsEntity>, PaginationOffsetLimit<ProductCost>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<ProductCostsEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new ProductCostsEntityToProductCostsMapper(
          this.productMapper
        )
      )
    )
  }

  @Cached()
  private getProductCostsWithUnitsRepository(): GetRepositoryMapper<PaginationOffsetLimit<ProductCostsWithUnitsEntity>, PaginationOffsetLimit<ProductCostsWithUnits>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<ProductCostsWithUnitsEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new ProductCostsWithUnitsEntityToProductCostsWithUnitsMapper(
          this.productMapper
        )
      )
    )
  }

  @Cached()
  private getSKUProductReportsRepository(): GetRepositoryMapper<PaginationOffsetLimit<SKUProductReportEntity>, PaginationOffsetLimit<SKUProductReport>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<SKUProductReportEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new SKUProductReportEntityToSKUProductReportMapper(
          this.productMapper
        )
      )
    )
  }

  @Cached()
  private getProductReportsRepository(): GetRepositoryMapper<PaginationOffsetLimit<ProductReportEntity>, PaginationOffsetLimit<ProductReport>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<ProductReportEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        new ProductReportEntityToProductReportMapper(
          new ListingEntityToListingMapper(),
          new CogsEntityToCogsMapper(),
          new CogsTypeEntityToCogsTypeMapper()
        )
      )
    )
  }

  @Cached()
  private getProductCogsRepository(): GetRepositoryMapper<ProductCogsEntity, ProductCogs> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      ProductCogsEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new ProductCogsEntityToProductCogsMapper(
        this.productMapper,
        new CogsEntityToCogsMapper()
      )
    )
  }

  @Cached()
  private getVoidRepository(): Repository<void> {
    return provideVoidNetworkDataSourceRepository(this.apiRequestService)
  }

  @Cached()
  private getTaskIdRepository(): PutRepositoryMapper<BulkTaskIdEntity, string> {
    const dataSource = provideDefaultNetworkDataSource<BulkTaskIdEntity>(
      this.apiRequestService,
      BulkTaskIdEntity
    )

    const repository = new SinglePutDataSourceRepository(dataSource)

    return new PutRepositoryMapper(
      repository,
      new BulkTaskIdToStringMapper(),
      new VoidMapper()
    )
  }

  @Cached()
  private getCogsUpdateStatusRepository(): GetRepository<CogsUpdateStatus[]> {
    const dataSource = provideDefaultArrayNetworkDataSource(
      this.apiRequestService,
      CogsUpdateStatusEntity
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new ArrayMapper(
        new CogsUpdateStatusEntityToCogsUpdateStatusMapper()
      )
    )
  }

  @Cached()
  private getPaginatedProductsRepository(): GetRepositoryMapper<PaginationOffsetLimit<ProductEntity>, PaginationOffsetLimit<Product>> {
    const dataSource = provideDefaultNetworkDataSource(
      this.apiRequestService,
      PaginationOffsetLimit<ProductEntity>
    )

    const repository = new SingleGetDataSourceRepository(dataSource)

    return new GetRepositoryMapper(
      repository,
      new PaginationOffsetLimitMapper(
        this.productMapper
      )
    )
  }

  public provideGetCatalog(): GetCatalogInteractor {
    return new GetCatalogInteractor(
      new GetInteractor<PaginationOffsetLimit<ProductCost>>(this.getCatalogRepository())
    )
  }

  public provideGetCogsIssues(): GetCogsIssuesInteractor {
    return new GetCogsIssuesInteractor(
      new GetInteractor<PaginationOffsetLimit<ProductCostsWithUnits>>(this.getProductCostsWithUnitsRepository())
    )
  }

  public provideGetCatalogFilters(): GetCatalogFiltersInteractor {
    return new GetCatalogFiltersInteractor(
      new GetInteractor<PaginationOffsetLimit<ProductCost>>(this.getCatalogRepository())
    )
  }

  public provideGetListingReports(): GetListingReportsInteractor {
    return new GetListingReportsInteractor(
      new GetInteractor<PaginationOffsetLimit<ListingReport>>(this.getListingReportsRepository())
    )
  }

  public provideGetOrders(): GetOrdersInteractor {
    return new GetOrdersInteractor(
      new GetInteractor<PaginationOffsetLimit<Order>>(this.getOrdersRepository())
    )
  }

  public provideGetProductReports(): GetProductReportsInteractor {
    return new GetProductReportsInteractor(
      new GetInteractor<PaginationOffsetLimit<ProductReport>>(this.getProductReportsRepository())
    )
  }

  public provideGetSkuProductReports(): GetSkuProductReportsInteractor {
    return new GetSkuProductReportsInteractor(
      new GetInteractor<PaginationOffsetLimit<SKUProductReport>>(this.getSKUProductReportsRepository())
    )
  }

  public provideGetProductCogs(): GetProductCogsInteractor {
    return new GetProductCogsInteractor(
      new GetInteractor<ProductCogs>(this.getProductCogsRepository())
    )
  }

  public provideReconcileCogs(): ReconcileCogsInteractor {
    return new ReconcileCogsInteractor(
      new PutInteractor<void>(this.getVoidRepository())
    )
  }

  public provideCreateCogsPeriod(): CreateCogsPeriodInteractor {
    return new CreateCogsPeriodInteractor(
      new PutInteractor<void>(this.getVoidRepository())
    )
  }

  public provideUpdateProductCogsHistoricInteractor(): UpdateProductCogsHistoricInteractor {
    return new UpdateProductCogsHistoricInteractor(
      new PutInteractor<void>(this.getVoidRepository())
    )
  }

  public provideUploadCogsInBulk(): UploadCogsInBulkInteractor {
    return new UploadCogsInBulkInteractor(
      new PutInteractor<string>(this.getTaskIdRepository())
    )
  }

  public provideGetProductProducts(): GetProductProductsInteractor {
    return new GetProductProductsInteractor(
      new GetInteractor<PaginationOffsetLimit<Product>>(this.getPaginatedProductsRepository())
    )
  }

  public provideGetCogsUpdateStatus(): GetCogsUpdateStatusInteractor {
    return new GetCogsUpdateStatusInteractor(
      new GetInteractor<CogsUpdateStatus[]>(this.getCogsUpdateStatusRepository())
    )
  }

  public provideMarkCogsUpdateAsViewed(): MarkCogsUpdateAsViewedInteractor {
    return new MarkCogsUpdateAsViewedInteractor(
      new DeleteInteractor(this.getVoidRepository())
    )
  }
}
