import { type Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { t } from 'i18next'
import Sparkles from '../../assets/heroicons/Sparkles'
import { useTracking } from '../../shared/providers/tracking.provider'
import { useNavigate } from '@solidjs/router'
import { AppRoutes } from '../../shared/app-routes'
import Button, { ButtonStyle } from '../shared/components/Button'

const StarterPlanWarning: Component = () => {
  const { trackEvent } = useTracking()
  const navigate = useNavigate()

  const onActionUpgrade = (): void => {
    trackEvent('settings', { action: 'upgrade' })
    navigate(AppRoutes.Subscription())
  }

  return (
    <div class="flex flex-col md:flex-row p-4 rounded-xl drop-shadow bg-white gap-2 w-full justify-between">
      <div class="flex flex-col gap-2">
        <div class="flex items-center gap-2 font-bold">
          <span class="text-japanese-600"><Sparkles/></span>
          <Trans key="ls_settings_upgrade_title"/>
        </div>
        <span class="text-sm" innerHTML={t('ls_settings_upgrade_description')} />
      </div>
      <Button action={onActionUpgrade} style={ButtonStyle.Primary} key="ls_settings_upgrade" />
    </div>
  )
}

export default StarterPlanWarning
