import { Trans } from '@mbarzda/solid-i18next'
import { type Component } from 'solid-js'
import ConnectEtsyButton from './shared/components/ConnectEtsyButton'

const NoEtsyShopError: Component = () => {
  return (
    <div class="flex justify-center px-4 sm:px-12">
      <div class="flex flex-col sm:flex-row sm:min-h-[430px] items-center justify-center max-w-[380px] sm:max-w-[910px] rounded-xl bg-white shadow-xl overflow-hidden sm:mt-20">
        <div class="flex w-full sm:w-1/2 flex-col justify-between py-10 px-8 sm:min-h-[430px]">
          <div class="flex flex-col gap-6">
            <span class="text-3xl font-semibold"><Trans key="ls_no_shop_title"/></span>
            <span class="text-base"><Trans key="ls_no_shop_line1"/></span>
            <span class="text-sm"><Trans key="ls_no_shop_line2"/></span>
            <span class="text-sm font-bold mb-10"><Trans key="ls_no_shop_line3"/></span>
          </div>
          <div>
            <ConnectEtsyButton />
          </div>
        </div>
        <div class="w-full sm:w-1/2 h-full max-h-[300px] sm:max-h-none">
          <img class="h-full w-full object-cover object-left" src='/images/presentation.svg' />
        </div>
      </div>
    </div>
  )
}

export default NoEtsyShopError
