import { Trans } from '@mbarzda/solid-i18next'
import { Show, createSignal, onMount, type Accessor, type Component } from 'solid-js'
import {
  addComponentToDOM,
  calculateTooltipPosition,
  type Size
} from '../../../shared/helpers/dom.functions'
import { useMeasureComponent } from '../functions/use-measure'
import TableColumnTooltip from './TableColumnTooltip'

export interface TableHeaderCellProps {
  readonly id: string
  readonly status?: 'asc' | 'desc'
  readonly setOrderBy: (id: string) => void
  readonly withHint?: boolean
  readonly sortable?: boolean
}

const TableHeaderCell: Component<TableHeaderCellProps> = (props) => {
  const [svgWidth, setSvgWidth] = createSignal(0)
  let svgRef: HTMLDivElement

  let tooltipSize: Accessor<Size> | undefined
  let removeTooltip: () => void = () => {}

  if (props.withHint) {
    tooltipSize = useMeasureComponent(<TableColumnTooltip columnId={props.id} />)
  }

  onMount(() => {
    setTimeout(() => {
      const width = svgRef.clientWidth
      setSvgWidth(width + 4)
    }, 1000)
  })

  const showTooltip = (event: MouseEvent): void => {
    if (!props.withHint || !tooltipSize?.()) return

    const hoveredEl = event.currentTarget as HTMLElement
    const elementRect = hoveredEl.getBoundingClientRect()

    const position = calculateTooltipPosition(tooltipSize(), elementRect)
    if (!position) return

    removeTooltip = addComponentToDOM(() =>
      <TableColumnTooltip columnId={props.id} position={position}/>
    )
  }

  const hideTooltip = (): void => {
    if (!props.withHint) return
    removeTooltip()
  }

  return (
    <div class="group inline-flex relative"
         classList={{ 'cursor-pointer': props.sortable }}
         onClick={() => { props.sortable && props.setOrderBy(props.id) }}
         onMouseEnter={showTooltip} onMouseLeave={hideTooltip}
    >
      <div class="grid" ref={el => (svgRef = el)}>
        <Trans key={`ls_table_col_${props.id}`} />
        <Show when={props.withHint}>
          <svg xmlns="http://www.w3.org/2000/svg" width={svgWidth()} height="2" viewBox={`0 0 ${svgWidth()} 2`} fill="none">
            <path d={`M0 1H${svgWidth()}`} stroke="#6B7280" stroke-linejoin="round" stroke-dasharray="2 4"/>
          </svg>
        </Show>
      </div>
      <Show when={props.sortable}>
        <span class="ml-2 h-5 flex-none rounded"
              classList={{
                'bg-gray-200 text-gray-900 group-hover:bg-gray-300': !!props.status,
                'invisible text-gray-400 group-hover:visible group-focus:visible': !props.status
              }}
        >
          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" style={{
            transform: props.status === 'asc' ? 'rotate(180deg)' : 'none'
          }}>
            <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
          </svg>
        </span>
      </Show>
    </div>
  )
}

export default TableHeaderCell
