import type { Component } from 'solid-js'
import { Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { useNumberFormatter } from '../../../shared/helpers/currency-formatter'
import MiniExclamationTriangle from '../../../assets/heroicons/MiniExclamationTriangle'

export interface CostsCogsValueCellProps {
  readonly value?: number
  readonly multipleCogs: boolean
  readonly warning: boolean
}

const CostsCogsValueCell: Component<CostsCogsValueCellProps> = (props) => {
  const { currencyFormatter } = useNumberFormatter()

  const getValue = (value: number | undefined): string => {
    if (props.multipleCogs) {
      return 'ls_generic_multiple'
    }

    return value !== undefined ? currencyFormatter(value)! : 'ls_generic_n_a'
  }

  return (
    <div class="flex gap-2 items-center">
      <Show when={props.warning}>
        <span class="text-red-500"><MiniExclamationTriangle /></span>
      </Show>
      <Trans key={getValue(props.value)} />
    </div>
  )
}

export default CostsCogsValueCell
