import { Trans } from '@mbarzda/solid-i18next'
import { useLocation } from '@solidjs/router'
import { Show, createEffect, createSignal, onCleanup, onMount, type Component } from 'solid-js'
import ArrowsRightLeft from '../../assets/heroicons/ArrowsRightLeft'
import BuildingStorefront from '../../assets/heroicons/BuildingStorefront'
import Cog8Tooth from '../../assets/heroicons/Cog8Tooth'
import ExclamationCircle from '../../assets/heroicons/ExclamationCircle'
import MiniExclamationCircle from '../../assets/heroicons/MiniExclamationCircle'
import { MainModule } from '../../features/main-module'
import {
  FetchStatus,
  type Integration,
  type IntegrationType,
  type Shop,
  type ShopType
} from '../../features/shop/domain/models/shop'
import { useError } from '../../shared/providers/error.provider'
import { useShop } from '../../shared/providers/shop.provider'
import { useTracking } from '../../shared/providers/tracking.provider'
import TextPlaceholder from '../shared/components/TextPlaceholder'
import { isValueOfEnum } from '../shared/functions/is-value-of-enum'
import DisconnectIntegrationConfirmDialog from './DisconnectIntegrationConfirmDialog'
import IntegrationConnectionErrorDialog from './IntegrationConnectionErrorDialog'
import IntegrationShopNotFoundDialog from './IntegrationShopNotFoundDialog'
import IntegrationShopNotMatchedDialog from './IntegrationShopNotMatchedDialog'
import IntegrationTryAgainDialog from './IntegrationTryAgainDialog'
import { IntegrationStatus, PRINTIFY_TRY_LATER_ERROR } from './Integrations'

export interface PrintifyIntegrationCardProps {
  onShopUpdated: () => Promise<void>
  type: IntegrationType
}

const PrintifyIntegrationCard: Component<PrintifyIntegrationCardProps> = (props) => {
  const [connectionStatus, setConnectionStatus] = createSignal<IntegrationStatus>(IntegrationStatus.Success)
  const [isDisconnecting, setIsDisconnecting] = createSignal(false)
  const [isLoading, setIsLoading] = createSignal(false)
  const [tempShop, setTempShop] = createSignal<Shop>()

  const location = useLocation()
  const { setError } = useError()
  const { selectedShopId, selectedShop, setSelectedShop } = useShop()
  const { trackEvent } = useTracking()

  const getShop = MainModule.getShopComponent().provideGetShop()
  const getIntegrationAuthUrl = MainModule.getShopComponent().provideGetIntegrationAuthUrl()
  const disconnectIntegration = MainModule.getShopComponent().provideDisconnectIntegration()

  const currentIntegration = (shop: Shop): Integration | undefined => shop.integrations[props.type]
  const isShopMissing = (shop: Shop): boolean => currentIntegration(shop) !== undefined && currentIntegration(shop)?.id === undefined

  onMount(() => {
    const params = new URLSearchParams(location.search)
    const { status, service } = Object.fromEntries(params)

    if (service !== props.type) return

    if (isValueOfEnum(status, IntegrationStatus)) {
      trackEvent(props.type.toUpperCase() + ' Connection', { status })
      setConnectionStatus(status as IntegrationStatus)
    } else {
      setConnectionStatus(IntegrationStatus.Success)
    }

    cleanUrlFromParams()
  })

  const shopUpdated = async (): Promise<void> => {
    setConnectionStatus(IntegrationStatus.Success)
    await props.onShopUpdated()
  }

  const isPrintifyEnabled = (): boolean => {
    return true // import.meta.env.VITE_PRINTIFY_ENABLED === 'true'
  }

  const cleanUrlFromParams = (): void => {
    const url = new URL(window.location.href)

    if (url.search) {
      url.search = ''
      window.history.pushState({}, '', url.toString())
    }
  }

  const connect = async (): Promise<void> => {
    const shopId = selectedShopId()
    if (!shopId) return

    try {
      window.location.href = await getIntegrationAuthUrl.execute(shopId, props.type)
      // eslint-disable-next-line
    } catch (error: any) {
      console.error(error)

      if (error.response.data.code === PRINTIFY_TRY_LATER_ERROR) {
        setConnectionStatus(IntegrationStatus.SystemBusy)
        return
      }
      setError(true)
    }
  }

  const retryConnection = async (): Promise<void> => {
    setConnectionStatus(IntegrationStatus.Success)
    await connect()
  }

  const disconnect = async (): Promise<void> => {
    setIsLoading(true)
    setIsDisconnecting(false)

    try {
      await disconnectIntegration.execute(selectedShopId()!, props.type)
      trackEvent(props.type.toUpperCase() + ' Connection', { status: 'Disconnected' })
    } catch (error) {
      console.error(error)
    }

    await props.onShopUpdated()
    setIsLoading(false)
  }

  const fetchingStateHasChanged = (shop: Shop): boolean => {
    return currentIntegration(shop)?.fetchStatus !== currentIntegration(selectedShop()!)?.fetchStatus
  }

  const fetchShop = async (): Promise<Shop | undefined> => {
    const shopId = selectedShop()?.id
    if (!shopId) return

    const shop = await getShop.execute(shopId)
    setTempShop(shop)
    if (fetchingStateHasChanged(shop)) {
      setSelectedShop(tempShop()!)
    }
    return shop
  }

  createEffect(() => {
    const shop = selectedShop()
    if (!shop) return

    const integration = currentIntegration(shop)
    if (!integration || integration.fetchStatus === FetchStatus.Completed || isShopMissing(shop)) return

    const intervalId = setInterval(async (): Promise<void> => {
      const shop = await fetchShop()
      if (currentIntegration(shop!)?.fetchStatus === FetchStatus.Completed) {
        trackEvent(props.type.toUpperCase() + ' Connection Start', {
          status: 'Complete'
        })
        clearInterval(intervalId)
      }
    }, 2000)

    onCleanup(() => { clearInterval(intervalId) })
  })

  return (
    <div class="flex flex-col gap-4 p-6 rounded-2xl bg-gray-50 w-full max-w-[357px] sm:min-w-[300px] h-fit">
      <div class="h-6 flex items-center">
        <img src={`/images/${props.type}-logo.svg`} alt={props.type} class="max-w-[141px] max-h-[24px] w-fit"/>
      </div>
      <span class="text-sm text-gray-500"><Trans key={`ls_integrations_${props.type}_description`}/></span>
      <Show when={selectedShop()}>{(shop) => (
        <div class="flex justify-between items-center">
          <Show when={!isLoading()} fallback={
            <TextPlaceholder widthCss="33%" height={'lg'}/>
          }>
            <Show when={!currentIntegration(shop())}>
              <div class="flex flex-col gap-4">
                <button
                  onClick={connect}
                  disabled={!isPrintifyEnabled()}
                  class="btn btn--primary">
                  <ArrowsRightLeft size={4}/> <Trans key="ls_generic_connect"/>
                </button>
                <Show when={!isPrintifyEnabled()}>
                  <div class="flex gap-2 text-red-500 text-sm">
                    <span class="min-w-4 mt-0.5">
                      <MiniExclamationCircle />
                    </span>
                    <Trans key="ls_integrations_disabled" options={{ type: 'Printify' }}/>
                  </div>
                </Show>
              </div>
            </Show>
            <Show when={currentIntegration(shop())}>{(integration) => (
              <div class="flex flex-col gap-4 w-full text-sm">
                <Show when={isShopMissing(shop())}>
                  <div class="flex items-center justify-between">
                    <div class="flex gap-2 items-center text-gray-500">
                      <ExclamationCircle/><Trans key={'ls_integrations_no_store_selected'}/>
                    </div>
                    <button onClick={() => setConnectionStatus(IntegrationStatus.ShopNotMatched)} class="text-gray-500"><Cog8Tooth/></button>
                  </div>
                </Show>
                <Show when={!isShopMissing(shop())}>
                  <div class="flex items-center justify-between">
                    <div class="flex gap-2 items-center text-gray-500">
                      <BuildingStorefront/>{integration().name}
                    </div>
                    <Show when={integration()?.fetchStatus === FetchStatus.Completed}>
                      <button onClick={() => setConnectionStatus(IntegrationStatus.ShopNotMatched)} class="text-gray-500"><Cog8Tooth/></button>
                    </Show>
                  </div>
                </Show>
                <div class="flex justify-between items-center">
                  <Show when={integration()?.fetchStatus === FetchStatus.Completed || isShopMissing(shop())}>
                    <div class="flex gap-2 items-center pl-2">
                      <div class="rounded-full h-2 w-2 bg-green-500" />
                      <span><Trans key="ls_generic_connected"/></span>
                    </div>
                    <button class="text-red-500" onClick={() => setIsDisconnecting(true)}>
                      <Trans key="ls_generic_disconnect"/>
                    </button>
                  </Show>
                  <Show when={integration()?.fetchStatus !== FetchStatus.Completed && !isShopMissing(shop())}>
                    <div class="flex gap-2 items-center pl-2">
                      <span class="relative flex h-2 w-2">
                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-500 opacity-75" />
                        <span class="relative inline-flex rounded-full h-2 w-2 bg-yellow-500" />
                      </span>
                      <span><Trans key="ls_integrations_fetching"/></span>
                    </div>
                  </Show>
                </div>
              </div>
            )}
            </Show>
          </Show>
        </div>
      )}</Show>
      <Show when={connectionStatus() === IntegrationStatus.SystemBusy}>
        <IntegrationTryAgainDialog cancel={() => setConnectionStatus(IntegrationStatus.Success)} retry={retryConnection} />
      </Show>
      <Show when={connectionStatus() === IntegrationStatus.Failed}>
        <IntegrationConnectionErrorDialog
          cancel={() => setConnectionStatus(IntegrationStatus.Success)} integration={props.type as unknown as ShopType}/>
      </Show>
      <Show when={connectionStatus() === IntegrationStatus.ShopNotFound}>
        <IntegrationShopNotFoundDialog
          cancel={() => setConnectionStatus(IntegrationStatus.Success)} integration={props.type}/>
      </Show>
      <Show when={connectionStatus() === IntegrationStatus.ShopNotMatched}>
        <IntegrationShopNotMatchedDialog
          integration={props.type}
          cancel={() => setConnectionStatus(IntegrationStatus.Success)}
          shopUpdated={shopUpdated}
        />
      </Show>
      <Show when={isDisconnecting()}>
        <DisconnectIntegrationConfirmDialog cancel={() => setIsDisconnecting(false)}
                                            confirm={disconnect} integration={props.type}/>
      </Show>
    </div>
  )
}

export default PrintifyIntegrationCard
