import type { Component } from 'solid-js'
import { type KPI } from '../../features/analytics/domain/models/kpi'
import { Trans } from '@mbarzda/solid-i18next'
import { useNumberFormatter } from '../../shared/helpers/currency-formatter'

export interface BreakdownChartLegendItemProps {
  readonly kpi: KPI
  readonly ratio: number
  readonly color: string
}

const BreakdownChartLegendItem: Component<BreakdownChartLegendItemProps> = (props) => {
  const { currencyFormatter, percentageFormatter } = useNumberFormatter()
  return (
    <div class="flex flex-col text-sm font-normal antialiased">
      <div class="flex items-center gap-2">
        <div class={`bg-${props.color} w-3 h-3 rounded-full`} /><span><Trans key={`ls_fin_break_${props.kpi.id}`} /></span>
      </div>
      <p class="ml-5 text-gray-500">{currencyFormatter(props.kpi.value)} <span class="ml-2">{percentageFormatter(props.ratio) ?? '-'}</span></p>
    </div>
  )
}

export default BreakdownChartLegendItem
