import { type Mapper } from '@coris-ts/repository/mapper/mapper'
import { type PurchaseProductEntity } from '../../data/entities/one-time-purchase.entity'
import { PurchaseProduct } from '../models/one-time-purchase'

export class PurchaseProductEntityToPurchaseProductMapper implements Mapper<PurchaseProductEntity, PurchaseProduct> {
  public map(from: PurchaseProductEntity): PurchaseProduct {
    return new PurchaseProduct(
      from.id,
      from.price,
      from.currency_code,
      from.offering,
      from.name
    )
  }
}
