import { onCleanup } from 'solid-js'

export const clickOutsideCalendar = (el: HTMLElement, accessor?: () => unknown): void => {
  const onClick = (e: Event): void => {
    const isCalendar = hasClickedIsDatepicker(e)
    const isInput = el.contains(e.target as Node)

    if (!isInput && !isCalendar) {
      accessor?.()
    }
  }

  document.body.addEventListener('click', onClick)

  onCleanup(() => {
    document.body.removeEventListener('click', onClick)
  })
}

const hasClickedIsDatepicker = (event: Event): boolean => {
  let targetElement = event.target as HTMLElement

  while (targetElement) {
    if (targetElement.classList && Array.from(targetElement.classList)?.find(c => c.includes('datepicker'))) {
      return true
    }
    targetElement = targetElement.parentNode as HTMLElement
  }

  return false
}
