import { createContext, createSignal, useContext, type JSX } from 'solid-js'
import { type User } from '../../features/auth/domain/models/user'
import { OfferingType } from '../../features/payment/domain/models/offering'
import {
  OneTimePurchaseType,
  type OneTimePurchase
} from '../../features/payment/domain/models/one-time-purchase'
import { type Purchases } from '../../features/payment/domain/models/purchases'
import { SubscriptionStatus, type Subscription } from '../../features/payment/domain/models/subscription'

export interface UserContextType {
  readonly currentUser: () => User | undefined
  readonly setCurrentUser: (user: User) => void
  readonly userPurchases: () => Purchases | undefined
  readonly setUserPurchases: (purchases: Purchases) => void
  readonly hasAccess: () => boolean
  readonly currentSubscription: () => Subscription | OneTimePurchase | undefined
}

const [currentUser, setCurrentUser] = createSignal<User>()
const [userPurchases, setUserPurchases] = createSignal<Purchases>()
const hasAccess = (): boolean => {
  if (userPurchases()?.isLifetime) {
    return true
  }

  const sub = currentSubscription()
  if (sub && sub.status !== SubscriptionStatus.PastDue) {
    return true
  }

  return false
}

const currentSubscription = (): Subscription | OneTimePurchase | undefined => {
  const proSub = userPurchases()?.subscriptions.find(sub => sub.productPrice.offeringType === OfferingType.Pro)
  if (proSub?.status && proSub.status !== SubscriptionStatus.Inactive) return proSub

  const starterSub = userPurchases()?.subscriptions.find(sub => sub.productPrice.offeringType === OfferingType.Starter)
  if (starterSub?.status && starterSub.status !== SubscriptionStatus.Inactive) return starterSub

  return userPurchases()?.oneTimePurchases.find(sub => sub.productPrice.offeringType === OneTimePurchaseType.Lifetime)
}

const defaultUserContext: UserContextType = {
  currentUser,
  setCurrentUser,
  userPurchases,
  setUserPurchases,
  hasAccess,
  currentSubscription
}

const UserContext = createContext<UserContextType>(defaultUserContext)

export const UserProvider = (props: { children: JSX.Element }): JSX.Element => {
  return (
    <UserContext.Provider value={defaultUserContext}>
      {props.children}
    </UserContext.Provider>
  )
}

export const useUser = (): UserContextType => {
  return useContext(UserContext)
}
