import { Trans } from '@mbarzda/solid-i18next'
import type { Component } from 'solid-js'

const NoCogsHistory: Component = () => {
  return (
    <div class="flex flex-col items-center justify-center gap-1 p-10">
      <img class="h-28" src='/images/no-results.png' alt="well-done" />
      <p class="text-base font-semibold"><Trans key="ls_cogs_no_previous_line1" /></p>
      <p class="text-sm"><Trans key="ls_cogs_no_previous_line2" /></p>
    </div>
  )
}

export default NoCogsHistory
