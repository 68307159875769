import { type Component, createSignal, onMount, Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { t } from 'i18next'
import BuildingStorefront from '../../assets/heroicons/BuildingStorefront'
import Button, { ButtonStyle } from '../shared/components/Button'
import { MainModule } from '../../features/main-module'
import { type OfferingProduct } from '../../features/payment/domain/models/offering'
import { useUser } from '../../public/auth/user-provider'
import { useTracking } from '../../shared/providers/tracking.provider'
import { useNumberFormatter } from '../../shared/helpers/currency-formatter'
import { OneTimePurchaseType } from '../../features/payment/domain/models/one-time-purchase'

const UnlimitedShopsCard: Component = () => {
  const { trackUser, trackEvent } = useTracking()
  const { currentSubscription, userPurchases, setUserPurchases, currentUser } = useUser()
  const { currencyFormatter } = useNumberFormatter()

  const [offering, setOffering] = createSignal<OfferingProduct>()
  const [isLoading, setIsLoading] = createSignal(false)

  const getOffering = MainModule.getPaymentComponent().provideGetOffering()
  const purchase = MainModule.getPaymentComponent().providePurchaseProduct()
  const getPurchases = MainModule.getPaymentComponent().provideGetPurchases()

  onMount(async(): Promise<void> => {
    const offeringId = 'profittree_addons'
    const offering = await getOffering.execute(offeringId)
    setOffering(offering.offerings.find(offering => offering.type === OneTimePurchaseType.UnlimitedShops)?.products[0])
  })

  const refreshUser = async (): Promise<void> => {
    const purchases = await getPurchases.execute()
    setUserPurchases(purchases)

    if (!userPurchases()?.canHaveMultipleShops) {
      setTimeout(async () => { await refreshUser() }, 2000)
      return
    }

    trackUser(currentUser()!, userPurchases())
  }

  const onActionBuyUnlimitedShops = async (): Promise<void> => {
    setIsLoading(true)
    trackEvent('Purchase', { product: 'Unlimited Shops' })
    const subscriptionId = userPurchases()?.isLifetime ? userPurchases()!.getBaseSubscription!.id : currentSubscription()!.id
    await purchase.execute(subscriptionId, offering()!.id)
    await refreshUser()
  }

  return (
    <div class={'flex flex-col md:flex-row p-4 rounded-xl drop-shadow bg-white gap-2 w-full justify-between'}>
      <div class="flex flex-col gap-2">
        <div class="flex items-center gap-2 font-bold">
          <span class="text-japanese-600"><BuildingStorefront /></span>
          <Trans key="ls_settings_unlimited_title"/>
        </div>
        <Show when={offering()}>
          <span class="text-sm" innerHTML={t('ls_settings_unlimited_description', { price: currencyFormatter(offering()?.price, 0) })} />
        </Show>
      </div>
      <Button disabled={!offering() || isLoading()} action={onActionBuyUnlimitedShops} style={ButtonStyle.Primary} key="ls_settings_unlimited_purchase" />
    </div>
  )
}

export default UnlimitedShopsCard
