import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { type GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetCatalogQuery } from '../../data/queries/get-product-costs.query'
import { type ProductCost, type ProductFilter } from '../models/product-cost'

export class GetCatalogInteractor {
  constructor(
    private readonly getProductCosts: GetInteractor<PaginationOffsetLimit<ProductCost>>
  ) {
  }

  public async execute (
    shopId: number,
    offset: number,
    limit: number,
    searchTerm: string | undefined,
    filter: ProductFilter
  ): Promise<PaginationOffsetLimit<ProductCost>> {
    return await this.getProductCosts.execute(new GetCatalogQuery(shopId, offset, limit, searchTerm, filter))
  }
}
