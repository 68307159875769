import { type Component } from 'solid-js'

import styles from './TextPlaceholder.module.scss'

export interface TextPlaceholderProps {
  readonly height?: 'sm' | 'md' | 'lg'
  readonly widthCss?: string
}

const TextPlaceholder: Component<TextPlaceholderProps> = (props) => {
  return (
    <div
      style={{ width: props.widthCss ?? 'w-full' }}
      class={`${styles.ph}
              ${props.height === 'lg'
                ? 'h-7 rounded-[14px]'
                : props.height === 'sm'
                  ? 'h-[18px] rounded-[9px]'
                  : 'h-5 rounded-[10px]'
                } opacity-10 bg-japanese-900`}
     />
  )
}

export default TextPlaceholder
