import { KPI, KPIType } from '../../features/analytics/domain/models/kpi'
import { ListingReport, ListingStatus } from '../../features/product/domain/models/listing'
import { NumericalDataType } from '../../shared/models/numerical-data-type'

const DEMO_IMAGES = [
  '/images/hidden/hidden1.jpg',
  '/images/hidden/hidden2.jpg',
  '/images/hidden/hidden3.jpg',
  '/images/hidden/hidden4.jpg',
  '/images/hidden/hidden5.jpg',
  '/images/hidden/hidden6.jpg'
]
const getBigNum = (): number => Math.random() * 8000 - 4000
const getSmallPosNum = (): number => Math.floor(Math.random() * 100)
const getPercentage = (): number => Math.floor(Math.random() * 100) - 50
const getSmallPosNumWithDecimals = (): number => Math.random() * 100

const getRandUnitKPI = (): KPI => new KPI(KPIType.NetSales, getSmallPosNum(), NumericalDataType.Units, getPercentage())
const getRandCurrencyKPI = (): KPI => new KPI(KPIType.NetSales, getBigNum(), NumericalDataType.Currency, getPercentage())
const getRandPercentage = (): KPI => new KPI(KPIType.NetSales, getSmallPosNumWithDecimals(), NumericalDataType.Percentage, getPercentage())
const getRandBool = (): boolean => Math.random() > 0.5

const getRandListingStatus = (): ListingStatus => {
  const statuses = Object.values(ListingStatus)
  return statuses[Math.floor(Math.random() * statuses.length)]
}

const randImage = (): string => DEMO_IMAGES[Math.floor(Math.random() * DEMO_IMAGES.length)]
export const getRandListing = (): ListingReport => new ListingReport(
  getBigNum(),
  'www.siroclabs.com',
  {
    sm: randImage(),
    md: randImage(),
    lg: randImage()
  },
  'Neutral Boho Pillow Set | Beige Sofa Pillow Set | White Mud Cloth | Decor Textured Pillow Cover Set | Lumbar Throw Pillow | Small Lumbar',
  getRandListingStatus(),
  getRandBool(),
  getRandUnitKPI(),
  getRandUnitKPI(),
  getRandCurrencyKPI(),
  getRandCurrencyKPI(),
  getRandCurrencyKPI(),
  getRandCurrencyKPI(),
  getRandPercentage(),
  getRandPercentage(),
  getRandUnitKPI()
)

export const MOCKED_LISTINGS: ListingReport[] = Array.from({ length: 15 }, getRandListing)
