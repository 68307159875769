// @ts-nocheck
import * as Sentry from '@sentry/browser'
import { Router } from '@solidjs/router'
import { DEV } from 'solid-js'
import { render } from 'solid-js/web'
import App from './App'
import './index.css'
import { TrackingProvider } from './shared/providers/tracking.provider'

Sentry.init({
  dsn: 'https://379a086aa0de3124767ba3bf710032f5@o4507035087273984.ingest.us.sentry.io/4507302023987200',
  release: import.meta.env.VITE_RELEASE_TAG,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: !DEV,
  beforeSend(event) {
    if (event.exception?.values) {
      const axiosError = event.exception.values.some((value) => value.type === 'AxiosError')
      if (axiosError) {
        return null
      }
    }

    return event
  }
})

const root = document.getElementById('root')

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error(
    'Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got misspelled?'
  )
}

const addIntercomSnippet = (): void => {
  const APP_ID = import.meta.env.VITE_INTERCOM_APP_ID;
  /* eslint-disable */
  (function() { const w = window; const ic = w.Intercom; if (typeof ic === 'function') { ic('reattach_activator'); ic('update', w.intercomSettings) } else { const d = document; const i = function() { i.c(arguments) }; i.q = []; i.c = function(args) { i.q.push(args) }; w.Intercom = i; const l = function() { const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + APP_ID; const x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x) }; if (document.readyState === 'complete') { l() } else if (w.attachEvent) { w.attachEvent('onload', l) } else { w.addEventListener('load', l, false) } } })()
}

addIntercomSnippet()

render(
  () => (
    <Router>
      <TrackingProvider>
        <App />
      </TrackingProvider>
    </Router>
  ),
  root!
)
