import { t } from 'i18next'
import type { Component } from 'solid-js'
import { Show } from 'solid-js'
import MiniChevronRight from '../../assets/heroicons/MiniChevronRight'
import MiniInformationCircle from '../../assets/heroicons/MiniInformationCircle'
import { type Listing } from '../../features/product/domain/models/listing'
import { type Position } from '../../shared/helpers/dom.functions'

export interface ListingListTooltipProps {
  readonly listing: Listing
  readonly numListings: number
  readonly position?: Position
}

const ListingListTooltip: Component<ListingListTooltipProps> = (props) => {
  return (
    <div
      class="flex flex-col border-[1px] border-gray-200 gap-2 w-max max-w-[480px] text-sm absolute bg-white p-4 shadow-lg rounded-lg z-30"
      style={{
        left: `${props.position?.left ?? 0}px`,
        top: `${props.position?.top ?? 24}px`
      }}>
      <span class="text-gray-900 font-semibold">
        {t('ls_shared_listings')}
      </span>
      <a href={props.listing.url} target="_blank" class="flex gap-6 justify-between text-gray-500">
        <div class="line-clamp-1 text-gray-900">
          {props.listing.title}
        </div>
        <span class="flex items-center text-gray-500">
          <MiniChevronRight size={4}/>
        </span>
      </a>
      <Show when={props.numListings > 1}>
        <span class="flex gap-2 font-xs text-gray-500 items-center">
          <MiniInformationCircle size={4}/> {t('ls_products_found_in_more', { count: props.numListings })}
        </span>
      </Show>
    </div>
  )
}

export default ListingListTooltip
