import type { PaginationOffsetLimit } from '@coris-ts/data/pagination'
import type { GetInteractor } from '@coris-ts/repository/interactor/get.interactor'
import { GetCatalogQuery } from '../../data/queries/get-product-costs.query'
import { ProductFilter, type ProductCost } from '../models/product-cost'

export type ValueByFilter<T extends string> = Record<T, number>

export class GetCatalogFiltersInteractor {
  constructor(
    private readonly getProductsByFilter: GetInteractor<PaginationOffsetLimit<ProductCost>>
  ) {
  }

  public async execute(
    shopId: number,
    searchTerm?: string
  ): Promise<ValueByFilter<ProductFilter>> {
    return await Promise.all([
      this.getProductsByFilter.execute(
        new GetCatalogQuery(shopId, 0, 1, searchTerm, ProductFilter.All)
      ),
      this.getProductsByFilter.execute(
        new GetCatalogQuery(shopId, 0, 1, searchTerm, ProductFilter.MultipleListing)
      ),
      this.getProductsByFilter.execute(
        new GetCatalogQuery(shopId, 0, 1, searchTerm, ProductFilter.WithoutCOGS)
      ),
      this.getProductsByFilter.execute(
        new GetCatalogQuery(shopId, 0, 1, searchTerm, ProductFilter.WithoutSKU)
      )
    ]).then(([all, multipleListing, withoutCOGS, withoutSKU]) => {
      return {
        [ProductFilter.All]: all.size,
        [ProductFilter.MultipleListing]: multipleListing.size,
        [ProductFilter.WithoutCOGS]: withoutCOGS.size,
        [ProductFilter.WithoutSKU]: withoutSKU.size
      }
    })
  }
}
