import { type Component, For, Show } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import SectionCard from '../account-settings/SectionCard'
import { useTracking } from '../../shared/providers/tracking.provider'
import { MainModule } from '../../features/main-module'
import PlusSM from '../../assets/heroicons/PlusSM'
import MiniEtsy from '../../assets/heroicons/MiniEtsy'
import { useShop } from '../../shared/providers/shop.provider'
import ExclamationTriangle from '../../assets/heroicons/ExclamationTriangle'
import TranslateWithAction from '../shared/components/TranslateWithAction'
import MiniExclamationTriangle from '../../assets/heroicons/MiniExclamationTriangle'
import { useUser } from '../../public/auth/user-provider'

const SettingsShopsCard: Component = () => {
  const { shops } = useShop()
  const { trackEvent } = useTracking()
  const { userPurchases } = useUser()
  const getEtsyAuthUrl = MainModule.getShopComponent().provideGetEtsyAuthUrl()

  const onActionAddNewShop = async (): Promise<void> => {
    trackEvent('ETSY Connection', { status: 'Start' })
    window.location.href = await getEtsyAuthUrl.execute()
  }

  return (
    <SectionCard>
      <div class="flex gap-2 items-center justify-between">
        <span class="font-bold text-2xl">
          <Trans key='ls_settings_shops'/>
        </span>
        <Show when={userPurchases()?.canHaveMultipleShops}>
          <button onClick={onActionAddNewShop} class="btn btn--primary">
            <PlusSM/> <Trans key="ls_settings_add_etsy_shop"/>
          </button>
        </Show>
      </div>
      <div class="h-px my-4 w-full bg-gray-200" />
      <div class="flex flex-col gap-4">
        <For each={shops()}>{(shop, index) => (
          <div class="flex p-4 rounded-lg border border-gray-200 items-center gap-4 justify-between">
            <div class="flex items-center gap-4">
              <img src={shop.imageUrl} alt={shop.name} class="h-10 w-10 flex-shrink-0 rounded"/>
              <span class="font-normal text-sm">
                {shop.name}
              </span>
            </div>
            <Show when={index() > 0 && !userPurchases()?.canHaveMultipleShops}>
              <span class="text-orange-500"><ExclamationTriangle /></span>
            </Show>
          </div>
        )}</For>
        <Show when={!userPurchases()?.canHaveMultipleShops && shops().length > 1}>
          <div class="flex gap-2 text-gray-500 text-sm items-start">
            <span class="h-fit mt-0.5">
              <MiniExclamationTriangle size={5} />
            </span>
            <TranslateWithAction key='ls_settings_disconnected_shops_warning'
                                 action={() => {
                                   window.Intercom('show')
                                 }}
            />
          </div>
        </Show>
      </div>
      <Show when={shops().length === 0}>
        <div class="flex flex-col gap-4 items-center text-gray-500">
          <div class="flex flex-col gap-2 items-center">
            <span class="font-semibold"><Trans key="ls_settings_no_shops_title" /></span>
            <span class=""><Trans key="ls_settings_no_shops_message" /></span>
          </div>
          <button onClick={onActionAddNewShop} class="btn btn--etsy">
            <MiniEtsy/><Trans key="ls_settings_connect_etsy"/>
          </button>
        </div>
      </Show>
    </SectionCard>
  )
}

export default SettingsShopsCard
