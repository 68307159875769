import { type PaginationOffsetLimit } from '@coris-ts/data/pagination'
import { Trans } from '@mbarzda/solid-i18next'
import { useLocation } from '@solidjs/router'
import type { Component } from 'solid-js'
import { createEffect, createSignal, For, onMount, Show } from 'solid-js'
import CloudArrowUp from '../../assets/heroicons/CloudArrowUp'
import CubeSolid from '../../assets/heroicons/CubeSolid'
import { MainModule } from '../../features/main-module'
import {
  type ValueByFilter
} from '../../features/product/domain/interactors/get-product-costs-filters.interactor'
import {
  ProductFilter,
  type ProductCost
} from '../../features/product/domain/models/product-cost'
import { useError } from '../../shared/providers/error.provider'
import { useShop } from '../../shared/providers/shop.provider'
import CogsBulkUploadDialog from '../cogs/CogsBulkUploadDialog'
import ProcessingCogsBanner from '../cogs/ProcessingCogsBanner'
import NoMissingCogs from '../shared/components/NoMissingCogs'
import SearchBar from '../shared/components/SearchBar'
import Table, { TableStatus, type TableColumn } from '../shared/components/Table'
import TablePagination from '../shared/components/TablePagination'
import { isValueOfEnum } from '../shared/functions/is-value-of-enum'
import { getGenericTableStatus } from '../shared/functions/table.functions'
import { executeWithUrlScope } from '../shared/functions/url-scoped-promise'
import CatalogFilters from './CatalogFilters'
import CatalogTableRow from './CatalogTableRow'

const Catalog: Component = () => {
  const DEFAULT_PAGE_SIZE = 15
  const [selectedFilter, setSelectedFilter] = createSignal<ProductFilter>(ProductFilter.All)
  const [searchTerm, setSearchTerm] = createSignal<string>()
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [isUploadingCogs, setIsUploadingCogs] = createSignal<boolean>(false)
  const [products, setProducts] = createSignal<PaginationOffsetLimit<ProductCost>>()
  const [productsByFilter, setProductsByFilter] = createSignal<ValueByFilter<ProductFilter>>()
  const [page, setPage] = createSignal(0)

  const location = useLocation()

  const { selectedShop } = useShop()
  const { setError } = useError()

  const columns: TableColumn[] = [
    { id: 'image' },
    { id: 'sku' },
    { id: 'listing_title' },
    { id: 'variant' },
    { id: 'price', withHint: true },
    { id: 'cogs', withHint: true }
  ]

  const getProductCosts = MainModule.getProductComponent().provideGetCatalog()
  const getProductCostsFilters = MainModule.getProductComponent().provideGetCatalogFilters()

  onMount((): void => {
    updateProductFilters()

    const params = new URLSearchParams(location.search)
    const { filter } = Object.fromEntries(params)
    if (isValueOfEnum(filter, ProductFilter)) {
      setSelectedFilter(filter as ProductFilter)
    } else {
      setSelectedFilter(ProductFilter.All)
    }
  })

  const updateUrl = (filter: ProductFilter): void => {
    const newUrl = filter !== ProductFilter.All ? `${location.pathname}?filter=${filter}` : location.pathname
    window.history.replaceState(undefined, '', newUrl)
  }

  const refreshTable = (page: number = 1): void => {
    const shop = selectedShop()
    if (!shop?.id || !shop.isFirstFetchReady) {
      return
    }

    setIsLoading(true)
    const offset = (page - 1) * DEFAULT_PAGE_SIZE

    executeWithUrlScope(
      async () => await getProductCosts.execute(
        shop.id,
        offset,
        DEFAULT_PAGE_SIZE,
        searchTerm(),
        selectedFilter()
      ), location
    )
      .then((products) => {
        setProducts(products)
      })
      .catch((error) => {
        console.error(error)
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const updateProductFilters = (shopId?: number): void => {
    if (!shopId) {
      return
    }

    executeWithUrlScope(
      async () =>
        await getProductCostsFilters.execute(
          shopId,
          searchTerm()
        ), location
    )
      .then((productsByFilter) => {
        setProductsByFilter(productsByFilter)
      })
      .catch((error) => {
        console.error(error)
        setError(true)
      })
  }

  const showNoMissingCogs = (): boolean => {
    return selectedFilter() === ProductFilter.WithoutCOGS && productsByFilter()?.cogs_issues === 0
  }

  const showNoResults = (): boolean => {
    return !showNoMissingCogs() && products()?.values.length === 0
  }

  createEffect(() => {
    searchTerm()
    updateUrl(selectedFilter())
    setPage(1)
  })

  createEffect(() => {
    refreshTable(page())
  })

  createEffect((): void => {
    updateProductFilters(selectedShop()?.id)
  })

  const getTableStatus = (): TableStatus => {
    return getGenericTableStatus(isLoading(), showNoResults(), !!searchTerm(), products()?.values)
  }

  let cogsStatusPullingAction = (): void => undefined
  const cogsUpdatedAction = (action: () => void): void => { cogsStatusPullingAction = action }

  const cogsUploaded = (): void => {
    setIsUploadingCogs(false)
    cogsStatusPullingAction()
    updateProductFilters(selectedShop()!.id)
    refreshTable(page())
  }

  return (
    <div class="flex flex-col gap-4">
      <ProcessingCogsBanner refresh={cogsUpdatedAction} />
      <div class="flex justify-between">
        <h1 class="page-title">
          <CubeSolid/><Trans key="ls_catalog_title"/>
        </h1>
        <button onClick={() => { setIsUploadingCogs(true) }}
                class="flex items-center gap-2 justify-center w-fit rounded-md !bg-japanese-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:!bg-japanese-800 transition duration-200">
          <CloudArrowUp size={5}/>
          <Trans key="Import COGS"/>
        </button>
      </div>
      <SearchBar setSearchTerm={setSearchTerm} placeholder="ls_search_title_sku"/>
      <CatalogFilters selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter}
                      productsByFilter={productsByFilter()}/>
      <div class="flow-root text-gray-900 sm:rounded-lg shadow overflow-hidden">
        <div class="max-w-full inline-block min-w-full align-middle ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <div class="table-scroll relative overflow-hidden overflow-x-auto w-full">
            <Table columns={columns} status={getTableStatus} tableId="product_costs">
              <For each={products()?.values}>{(product) => (
                <CatalogTableRow product={product}/>
              )}</For>
              <Show when={showNoMissingCogs()}>
                <td class="h-60">
                  <div class="absolute left-0 w-full top-0 bottom-0 flex items-center justify-center">
                    <NoMissingCogs />
                  </div>
                </td>
              </Show>
            </Table>
          </div>
          <Show when={[TableStatus.Loaded, TableStatus.Loading].includes(getTableStatus())}>
            <TablePagination totalCount={products()?.size ?? 0} page={page} setPage={setPage}/>
          </Show>
        </div>
      </div>
      <Show when={isUploadingCogs()}>
        <CogsBulkUploadDialog cancel={() => setIsUploadingCogs(false)} uploaded={cogsUploaded} />
      </Show>
    </div>
  )
}

export default Catalog
