import { KPI, KPIType } from '../../features/analytics/domain/models/kpi'
import { Breakdown } from '../../features/product/domain/models/breakdown'
import { CogsType } from '../../features/product/domain/models/cogs'
import { Listing, ListingStatus } from '../../features/product/domain/models/listing'
import {
  ProductReport
} from '../../features/product/domain/models/product'
import { NumericalDataType } from '../../shared/models/numerical-data-type'

const DEMO_IMAGES = [
  '/images/hidden/hidden1.jpg',
  '/images/hidden/hidden2.jpg',
  '/images/hidden/hidden3.jpg',
  '/images/hidden/hidden4.jpg',
  '/images/hidden/hidden5.jpg',
  '/images/hidden/hidden6.jpg'
]

const lastWeek = new Date()
lastWeek.setDate(lastWeek.getDate() - 7)

const MockedBreakdown = new Breakdown(
  [
    new KPI(KPIType.GrossSales, 14976.45, NumericalDataType.Currency, 3, 14527.16),
    new KPI(KPIType.ShippingCollected, 4253.50, NumericalDataType.Currency, -2, 4338.57),
    new KPI(KPIType.Discounts, 4253.50, NumericalDataType.Currency, -2, -4338.57),
    new KPI(KPIType.Refunds, 722.95, NumericalDataType.Currency, 1, -765.22)
  ],
  new KPI(KPIType.UnitsSold, 300.00, NumericalDataType.Units, 3, 200.00),
  new KPI(KPIType.NetSales, 10000.00, NumericalDataType.Currency, 2, 9800.00)
)

const getRandomSKU = (): string => {
  const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const numbers = '0123456789'

  const pattern = 'XXXNN-XX-NNNNN-NNNX'

  return pattern.split('').map(char => {
    if (char === 'X') {
      return uppercaseLetters.charAt(Math.floor(Math.random() * uppercaseLetters.length))
    } else if (char === 'N') {
      return numbers.charAt(Math.floor(Math.random() * numbers.length))
    }
    return char
  }).join('')
}

const getBigNum = (): number => Math.random() * 8000 - 4000
const getSmallPosNum = (): number => Math.floor(Math.random() * 100)
const getPercentage = (): number => Math.floor(Math.random() * 100) - 50

const OpExpenses = new KPI(KPIType.OperatingExpenses, getBigNum(), NumericalDataType.Currency, getPercentage(), getSmallPosNum(), MockedBreakdown)
const randImage = (): string => DEMO_IMAGES[Math.floor(Math.random() * DEMO_IMAGES.length)]
const OneListing = (): Listing => new Listing(1, 'www.siroclabs.com', {
  sm: randImage(),
  md: randImage(),
  lg: randImage()
}, 'Neutral Boho Pillow Set | Beige Sofa Pillow Set | White Mud Cloth | Decor Textured Pillow Cover Set | Lumbar Throw Pillow | Small Lumbar', ListingStatus.Active)

const getRandProductReport = (): ProductReport => new ProductReport(
  getBigNum(),
  getRandomSKU(),
  'Neutral Boho Pillow Set | Beige Sofa Pillow Set | White Mud Cloth | Decor Textured Pillow Cover Set | Lumbar Throw Pillow | Small Lumbar',
  OneListing(),
  CogsType.None,
  getSmallPosNum(),
  getSmallPosNum(),
  true,
  false,
  {
    fromDate: new Date(),
    amount: getSmallPosNum(),
    currency: 'USD'
  },
  new Date(),
  false,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses,
  OpExpenses
)

export const PRODUCTS_REPORTS = Array.from({ length: 10 }, getRandProductReport)
