import { Trans } from '@mbarzda/solid-i18next'
import { useLocation, useParams } from '@solidjs/router'
import { Show, createEffect, createSignal, type Component } from 'solid-js'
import logoAnimation from '../../../assets/animations/logo-animated.json'
import { KPIType, type KPI } from '../../../features/analytics/domain/models/kpi'
import { MainModule } from '../../../features/main-module'
import { type ListingReport } from '../../../features/product/domain/models/listing'
import { type PeriodsState } from '../../../features/shared/period-state'
import { KPIGroupId } from '../../../shared/models/kpi-group'
import { useError } from '../../../shared/providers/error.provider'
import { usePeriods } from '../../../shared/providers/period.provider'
import { useShop } from '../../../shared/providers/shop.provider'
import { AnalyticsConfig } from '../../shared/analytics.vm'
import AnalyticsChart from '../../shared/components/AnalyticsChart'
import LottieAnimation from '../../shared/components/LottieAnimation'
import PerUnitMainStats from '../../shared/components/PerUnitMainStats'
import StatsGroup from '../../shared/components/StatsGroup'
import { getKPIVMsInGroup } from '../../shared/functions/kpi.functions'
import { executeWithUrlScope } from '../../shared/functions/url-scoped-promise'
import ListingProductTable from './ListingProductTable'
import ListingSummaryBox from './ListingSummaryBox'

const Listing: Component = () => {
  const [selectedKpiType, setSelectedKpiType] = createSignal(KPIType.OperatingProfit)
  const [selectedKpi, setSelectedKpi] = createSignal<KPI>()
  const [kpis, setKpis] = createSignal<KPI[]>([])
  const [isLoading, setIsLoading] = createSignal<boolean>(true)
  const [listing, setListing] = createSignal<ListingReport>()

  const { selectedShopId } = useShop()
  const { periods } = usePeriods()
  const { setError } = useError()

  const analyticsComponent = MainModule.getAnalyticsComponent()
  const getListing = analyticsComponent.provideGetListingReport()
  const getTimeseries = analyticsComponent.provideGetListingAnalyticsTimeseries()

  const location = useLocation()

  createEffect(() => {
    const shopId = selectedShopId()
    if (!shopId) return

    const params = useParams()
    reloadListing(shopId, +params.listingId, periods())
  })

  const reloadListing = (shopId: number, listingId: number, period: PeriodsState): void => {
    setIsLoading(true)
    executeWithUrlScope(
      async () => {
        await getListing.execute(shopId, listingId, period)
          .then((listing) => {
            setListing(listing)
            setKpis([
              listing.cogs,
              listing.unitsSold,
              listing.roi,
              listing.opExpenses,
              listing.opProfit,
              listing.profitMargin,
              listing.breakEvenRoas,
              listing.orders,
              listing.netSales
            ])
          })
      }, location
    )
      .catch((error) => {
        console.error(error)
        setError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  createEffect((): void => {
    setSelectedKpi(
      kpis().find((KPI) => KPI.id === selectedKpiType())!
    )
  })

  return (
    <>
      <h1 class="mb-4 page-title"><Trans key="ls_listing_title"/></h1>
      <div class="flex flex-col gap-9">
        <ListingSummaryBox listing={listing()} />
        <div class="grid gap-9 content-center">
          <PerUnitMainStats kpis={getKPIVMsInGroup(kpis(), KPIGroupId.Main)}
                            selectedKPI={selectedKpiType}
                            setSelectedKPI={setSelectedKpiType} units={listing()?.unitsSold.value}
                            missingCogs={listing()?.missingCogs}/>
          <Show when={!isLoading() && selectedKpi()}>{(kpi) =>
            <AnalyticsChart kpi={kpi()} getTimeseriesAction={getTimeseries}
                            entityId={listing()?.id}/>
          }</Show>
          <Show when={isLoading() || !selectedKpi()}>
            <div
              class="w-full h-[421px] rounded-lg bg-white flex items-center justify-center shadow">
              <LottieAnimation animationData={logoAnimation} width="2rem"/>
            </div>
          </Show>
          <div class="sm:hidden flex flex-col gap-6">
            <StatsGroup config={AnalyticsConfig.performance!}
                        kpis={getKPIVMsInGroup(kpis(), KPIGroupId.Performance)} color="green"
                        selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>

            <StatsGroup config={AnalyticsConfig.listing_orders!}
                        kpis={getKPIVMsInGroup(kpis(), KPIGroupId.ListingOrders)} color="blue"
                        selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>

            <StatsGroup config={AnalyticsConfig.listing_advertisement!}
                        kpis={getKPIVMsInGroup(kpis(), KPIGroupId.ListingAdvertisement)} color="purple"
                        selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>
          </div>
          <div class="hidden sm:flex flex-col gap-6">
            <StatsGroup direction={'row'} config={AnalyticsConfig.performance!}
                        kpis={getKPIVMsInGroup(kpis(), KPIGroupId.Performance)} color="green"
                        selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>

            <div class="flex gap-6">
              <StatsGroup direction={'row'} config={AnalyticsConfig.listing_orders!}
                          kpis={getKPIVMsInGroup(kpis(), KPIGroupId.ListingOrders)} color="blue"
                          selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>

              <StatsGroup direction={'row'} config={AnalyticsConfig.listing_advertisement!}
                          kpis={getKPIVMsInGroup(kpis(), KPIGroupId.ListingAdvertisement)} color="purple"
                          selectedKPI={selectedKpiType} setSelectedKPI={setSelectedKpiType}/>
            </div>
          </div>
        </div>

        <ListingProductTable listing={listing()}/>
      </div>
    </>
  )
}

export default Listing
