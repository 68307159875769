import { useSearchParams } from '@solidjs/router'
import { Show, createSignal, onMount, type Component } from 'solid-js'
import { type User } from '../features/auth/domain/models/user'
import { MainModule } from '../features/main-module'
import { ShopType, type Shop } from '../features/shop/domain/models/shop'
import IntegrationConnectionErrorDialog from '../private/integrations/IntegrationConnectionErrorDialog'
import ShopReconnectedDialog from '../private/shared/components/ShopReconnectedDialog'
import ShopReconnectionMismatchDialog from '../private/shared/components/ShopReconnectionMismatchDialog'
import { useShop } from '../shared/providers/shop.provider'
import { useTracking } from '../shared/providers/tracking.provider'
import { useUser } from './auth/user-provider'
import LoadingScreen from './loading-screen'

export interface ProtectedRouteProps {
  readonly component: Component
}

const ProtectedRoute: Component<ProtectedRouteProps> = (props) => {
  const [isLoading, setIsLoading] = createSignal(true)
  const [isRedundant, setIsRedundant] = createSignal(false)
  const [reconnectionMismatch, setReconnectionMismatch] = createSignal<ShopType | undefined>(undefined)
  const [hasEtsyConnectionIssues, setHasEtsyConnectionIssues] = createSignal(false)

  const [searchParams, setSearchParams] = useSearchParams()
  const { shops, setShops, selectedShop, selectedShopId, setSelectedShop } = useShop()
  const { currentUser, userPurchases } = useUser()
  const { trackEvent } = useTracking()

  const getShops = MainModule.getShopComponent().provideGetShops()

  const initIntercom = (user: User): void => {
    window.Intercom('boot', {
      app_id: import.meta.env.VITE_INTERCOM_APP_ID,
      name: user?.name,
      user_id: user?.id,
      user_email: user?.email,
      user_hash: user?.intercomHash
    })
  }

  onMount(async (): Promise<void> => {
    initIntercom(currentUser()!)
    await loadShops()
    setIsLoading(false)
  })

  const cleanQueryParams = (): void => {
    const cleaningObject: Record<string, undefined> = {}
    for (const key of Object.keys(searchParams)) {
      cleaningObject[key] = undefined
    }

    setSearchParams(cleaningObject)
  }

  const findShopInList = (shopId?: number): Shop | undefined => {
    return shops().find(shop => shop.id === shopId)
  }

  const selectShop = (): void => {
    const shopId = searchParams.shop_id
    if (shopId) {
      const shop = findShopInList(+shopId)
      if (shop) setSelectedShop(shop)
    }

    const service = searchParams.service
    if (service === 'etsy') {
      const status = searchParams.status
      trackEvent('ETSY Connection', { status })

      if (!['success', 'reconnected', 'redundant_connection', 'reconnection_shop_mismatch'].includes(status)) {
        setHasEtsyConnectionIssues(true)
        cleanQueryParams()
        return
      }

      if (searchParams.shop_id) {
        const shop = findShopInList(+searchParams.shop_id)

        if (shop) {
          if (status === 'redundant_connection') {
            setIsRedundant(true)
          }
          if (status === 'reconnection_shop_mismatch') {
            setReconnectionMismatch(ShopType.Etsy)
          }
        }
      }

      cleanQueryParams()
    }

    if (service === ShopType.Printful || service === ShopType.Printify) {
      const status = searchParams.status
      trackEvent(service.toUpperCase() + ' Connection', { status })

      if (shopId) {
        if (status === 'reconnection_shop_mismatch') {
          setReconnectionMismatch(service)
        }
      }

      cleanQueryParams()
    }

    if (shops().length > 0) {
      if (!selectedShopId() || userPurchases()!.isPro) {
        setSelectedShop(shops()[0])
        return
      }

      const currentShop = findShopInList(selectedShopId())
      if (currentShop) {
        setSelectedShop(currentShop)
      }
    }
  }

  const loadShops = async (): Promise<void> => {
    await getShops.execute().then(shops => {
      setShops(shops)
      selectShop()
      setIsLoading(false)
    })
  }

  const getShopName = (shop: Shop, shopType: ShopType): string | undefined => {
    switch (shopType) {
      case ShopType.Etsy:
        return shop.name
      case ShopType.Printful:
        return shop.integrations.printful?.name
      case ShopType.Printify:
        return shop.integrations.printify?.name
    }
  }

  const reconnect = async (): Promise<void> => {
    const startShopConnection = MainModule.getShopComponent().provideStartShopConnection()
    void startShopConnection.execute(reconnectionMismatch(), selectedShopId(), trackEvent)
  }

  return (
    <Show when={!isLoading()} fallback={<LoadingScreen />}>
      <props.component />
      <Show when={isRedundant()}>
        <ShopReconnectedDialog cancel={() => setIsRedundant(false)} />
      </Show>
      <Show when={reconnectionMismatch()}>{(shopType) => (
        <Show when={selectedShop()}>{(shop) => (
          <Show when={getShopName(shop(), shopType())}>{(shopName) => (
            <ShopReconnectionMismatchDialog retry={reconnect} cancel={() => { setReconnectionMismatch(undefined) }} shopType={shopType()} shopName={shopName()} />
          )}</Show>
        )}</Show>
      )}</Show>
      <Show when={hasEtsyConnectionIssues()}>
        <IntegrationConnectionErrorDialog integration={ShopType.Etsy} cancel={() => setHasEtsyConnectionIssues(false)} />
      </Show>
    </Show>
  )
}

export default ProtectedRoute
