import { Trans } from '@mbarzda/solid-i18next'
import { Show, type Component } from 'solid-js'
import logoAnimation from '../../assets/animations/logo-animated.json'
import ArrowRight from '../../assets/heroicons/ArrowRight'
import MiniInformationCircle from '../../assets/heroicons/MiniInformationCircle'
import {
  OfferingProductInterval,
  type OfferingProduct
} from '../../features/payment/domain/models/offering'
import { type SubscriptionChange } from '../../features/payment/domain/models/subscription-change'
import { useNumberFormatter } from '../../shared/helpers/currency-formatter'
import Dialog from '../shared/components/Dialog/Dialog'
import DialogActions from '../shared/components/Dialog/DialogActions'
import LottieAnimation from '../shared/components/LottieAnimation'
import TranslateWithAction from '../shared/components/TranslateWithAction'

export interface PlanChangeDialogProps {
  readonly onActionAccept: (hasAccepted: boolean) => void
  readonly fromProduct: OfferingProduct
  readonly toProduct: OfferingProduct
  readonly preview?: SubscriptionChange
}

const PlanChangeDialog: Component<PlanChangeDialogProps> = (props) => {
  const { currencyFormatter } = useNumberFormatter()

  const getPeriodString = (product: OfferingProduct): string => {
    return product.billingInterval.interval === OfferingProductInterval.Month ? 'ls_shared_month_short' : 'ls_shared_year_short'
  }

  const goToSupport = (): void => {
    window.open('https://support.profittree.io/en/articles/9652280-how-we-apply-plan-changes', '_blank')
  }

  return (
    <Dialog maxWidth={'606px'}>
      <div class="flex flex-col gap-4 p-6">

        <span class="text-gray-900 font-bold">Change Summary</span>
        <span class="text-xs text-gray-900">Upon confirmation, your plan will change immediately as outlined below:</span>

        <div class="flex justify-between items-center gap-2">
          <div class="w-1/2 bg-gray-100 flex flex-col gap-1 p-4 text-gray-900 text-sm rounded-lg">
            <span class="font-bold">{props.fromProduct.name}</span>
            <span>{currencyFormatter(props.fromProduct.price)}/<Trans key={getPeriodString(props.fromProduct)}/></span>
          </div>
          <ArrowRight/>
          <div class="w-1/2 bg-gray-100 flex flex-col gap-1 p-4 text-gray-900 text-sm rounded-lg">
            <span class="font-bold">{ props.toProduct.name }</span>
            <span>{ currencyFormatter(props.toProduct.price) }/<Trans key={getPeriodString(props.toProduct)}/></span>
          </div>
        </div>
        <div class="h-px bg-gray-200" />
        <Show when={props.preview} fallback={
          <div class="w-full flex justify-center h-10">
            <LottieAnimation animationData={logoAnimation} width="32px"/>
          </div>
        }>{(preview) => (
          <>
            <Show when={preview().creditToBalance && !preview().amountDueToday}>
              <div class="text-gray-900 text-sm flex justify-end gap-2">
                <span class="font-bold">Prorated Credit to Balance:</span>
                <span>{currencyFormatter(preview()?.creditToBalance)}</span>
              </div>
            </Show>
            <Show when={!preview().creditUsed && !preview().creditToBalance}>
              <div class="text-gray-900 text-sm flex justify-end gap-2">
                <span class="font-bold">Prorated Cost:</span>
                <span>{currencyFormatter(preview()?.proratedCost)}</span>
              </div>
            </Show>
            <Show when={preview().creditUsed}>
              <div class="flex flex-col gap-2">
                <div class="text-gray-900 text-sm flex justify-end gap-2">
                  <span class="font-bold">Prorated Cost:</span>
                  <span>{currencyFormatter(preview()?.proratedCost)}</span>
                </div>
                <div class="text-gray-900 text-sm flex justify-end gap-2">
                  <span>Used Credit:</span>
                  <span>{currencyFormatter(preview()?.creditUsed)}</span>
                </div>
                <div class="text-gray-900 text-sm flex justify-end gap-2">
                  <span class="font-bold">Amount Due Today:</span>
                  <span>{currencyFormatter(preview()?.amountDueToday)}</span>
                </div>
              </div>
            </Show>
          </>
        )}</Show>
        <div class="h-px bg-gray-200" />
        <div class="flex gap-2 text-gray-500">
          <span class="w-4"><MiniInformationCircle size={4}/></span>
          <span class="text-xs">
            <TranslateWithAction key={'ls_plan_change_info'} action={goToSupport}/>
          </span>
        </div>
      </div>
      <DialogActions>
        <button
          onClick={() => {
            props.onActionAccept(false)
          }}
          class="btn btn--white">
          <Trans key="ls_plan_change_cancel"/>
        </button>
        <button
          onClick={() => {
            props.onActionAccept(true)
          }}
          disabled={!props.preview}
          class="btn btn--primary">
          <Trans key="ls_plan_change_accept"/>
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default PlanChangeDialog
