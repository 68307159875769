import { Trans } from '@mbarzda/solid-i18next'
import { CheckoutEventNames } from '@paddle/paddle-js'
import { t } from 'i18next'
import { Show, createSignal, onMount, type Component } from 'solid-js'
import logoAnimation from '../../assets/animations/logo-animated.json'
import MiniChatBubbleLeftRight from '../../assets/heroicons/ChatBubbleLeftRightSolid'
import MiniSparkles from '../../assets/heroicons/MiniSparkles'
import MiniTrophy from '../../assets/heroicons/MiniTrophy'
import MiniVideoCamera from '../../assets/heroicons/MiniVideoCamera'
import { MainModule } from '../../features/main-module'
import { type OfferingProduct } from '../../features/payment/domain/models/offering'
import {
  SubscriptionStatus,
  type Subscription
} from '../../features/payment/domain/models/subscription'
import { useUser } from '../../public/auth/user-provider'
import { useNumberFormatter } from '../../shared/helpers/currency-formatter'
import { formatDateToRegular } from '../../shared/helpers/date.functions'
import { usePaddle } from '../../shared/providers/paddle.provider'
import { useTracking } from '../../shared/providers/tracking.provider'
import { SubscriptionStatusColor } from '../account-settings/SubscribedSectionCard'
import Button, { ButtonStyle } from '../shared/components/Button'
import CancelSubscriptionDialog from '../shared/components/CancelSubscriptionDialog'
import LottieAnimation from '../shared/components/LottieAnimation'
import StatusCell from '../shared/components/StatusCell'

const GroupCoachingSessionsCard: Component = () => {
  const { paddle } = usePaddle()
  const { trackEvent, trackUser } = useTracking()
  const { currentUser, userPurchases, setUserPurchases } = useUser()
  const { currencyFormatter } = useNumberFormatter()

  const [offering, setOffering] = createSignal<OfferingProduct | undefined>(undefined)
  const [subscription, setSubscription] = createSignal<Subscription | undefined>(undefined)
  const [isCanceling, setIsCanceling] = createSignal(false)
  const [isLoading, setIsLoading] = createSignal(true)

  const getPurchases = MainModule.getPaymentComponent().provideGetPurchases()
  const cancelSubscription = MainModule.getPaymentComponent().provideCancelSubscription()
  const reactivateSubscription = MainModule.getPaymentComponent().provideReactivateSubscription()

  onMount(async (): Promise<void> => {
    const offeringType = 'coaching'
    const getOffering = MainModule.getPaymentComponent().provideGetOffering()

    const [offer, purchases] = await Promise.all([
      getOffering.execute(offeringType),
      getPurchases.execute()
    ])

    setOffering(offer.offerings[0].products[0])
    setSubscription(purchases.getGroupCoaching())

    setIsLoading(false)
  })

  const showCheckout = async (): Promise<void> => {
    const offeringId = offering()?.id
    if (!offeringId) return

    paddle()?.Update({
      eventCallback: async (data) => {
        if (data.name === CheckoutEventNames.CHECKOUT_COMPLETED) {
          setTimeout(async () => {
            trackEvent(
              'Subscription Checkout',
              { completed: true, offering: offering()?.name }
            )
            paddle()?.Checkout.close()
            await refreshPurchases(true)
          }, 1000)
        } else if (data.name) {
          trackEvent(
            'Subscription Checkout',
            { completed: false, reason: data.name }
          )
        }
      }
    })

    paddle()?.Checkout.open({
      customer: {
        id: currentUser()!.customerId
      },
      items: [{ priceId: offeringId, quantity: 1 }],
      customData: {
        email: currentUser()!.email,
        fp_tid: window.FPROM?.data.tid ?? ''
      }
    })
  }

  const isSubscriptionActive = (): boolean => userPurchases()?.getGroupCoaching()?.status === SubscriptionStatus.Active
  const isUserSubscribed = (): boolean => !!userPurchases()?.isSubscribedToGroupCoachingSessions

  const refreshPurchases = async (mustBeSubscribed: boolean): Promise<void> => {
    if (mustBeSubscribed !== isSubscriptionActive()) {
      const purchases = await getPurchases.execute()
      setUserPurchases(purchases)

      if (mustBeSubscribed !== isSubscriptionActive()) {
        setTimeout(async () => { await refreshPurchases(mustBeSubscribed) }, 2000)
        return
      }
    }

    setSubscription(userPurchases()!.getGroupCoaching())
    trackUser(currentUser()!, userPurchases())
    setIsLoading(false)
  }

  const onActionCancelSubscription = async (): Promise<void> => {
    setIsLoading(true)
    setIsCanceling(false)
    trackEvent('Subscription Cancel', { offering: offering()?.name })
    await cancelSubscription.execute(subscription()!.id)
    await refreshPurchases(false)
  }

  const onReactivateSubscription = async (subscription: Subscription): Promise<void> => {
    setIsLoading(true)
    trackEvent('Subscription Reactivate', { offering: offering()?.name })
    await reactivateSubscription.execute(subscription.id)
    await refreshPurchases(true)
  }

  return (
    <>
      <Show when={isCanceling()}>
        <CancelSubscriptionDialog cancel={ async () => { await onActionCancelSubscription() } } close={() => { setIsCanceling(false) }} subscription={subscription()!}/>
      </Show>
      <div class="rounded-lg flex flex-col w-full 2xl:w-full max-w-[572px] bg-white overflow-hidden h-fit">
        <img src='/images/etsy-community-coaching.png' alt="Group Coaching Sessions With Hannah"/>
        <div class="flex flex-col gap-6 px-6 py-4">
          <div class="flex flex-col gap-2">
            <span class="text-sm text-orange-500 font-bold"><Trans key="ls_group_coaching_coaching" /></span>
            <span class="text-2xl font-bold"><Trans key="ls_group_coaching_title" /></span>
          </div>
          <Show when={!isLoading()} fallback={
            <div class="w-full h-[140px] rounded-lg bg-blue_gray-100 flex items-center justify-center">
              <LottieAnimation animationData={logoAnimation} width="2rem"/>
            </div>
          }>
            <Show when={!isUserSubscribed()}>
              <div class="flex flex-col gap-2 text-sm">
                <span class="text-xs text-gray-500 font-bold"><Trans key="ls_group_coaching_included" /></span>
                <div class="flex gap-2">
                  <span class="text-green-500"><MiniVideoCamera /></span>
                  <span innerHTML={t('ls_group_coaching_desc1')} />
                </div>
                <div class="flex gap-2">
                  <span class="text-green-500"><MiniSparkles /></span>
                  <span innerHTML={t('ls_group_coaching_desc2')} />
                </div>
                <div class="flex gap-2">
                  <span class="text-green-500"><MiniChatBubbleLeftRight /></span>
                  <span innerHTML={t('ls_group_coaching_desc3')} />
                </div>
                <div class="flex gap-2">
                  <span class="text-green-500"><MiniTrophy /></span>
                  <span innerHTML={t('ls_group_coaching_desc4')} />
                </div>
              </div>
              <div class="h-px bg-gray-200 w-full" />
              <Show when={offering()}>{(off) => (
                <div class="flex flex-col gap-4">
                  <div class="flex flex-col gap-2">
                    <span class="text-sm font-semibold">
                      <Trans key="ls_group_coaching_special_offer"/>
                    </span>
                    <div class="text-sm flex gap-1 font-bold items-baseline">
                      <span class="text-4xl">{ currencyFormatter(off().price) }</span>
                      <span class="text-sm"><Trans key="ls_group_coaching_temporality"/></span>
                    </div>
                  </div>
                  <button class="btn btn--primary !w-full" onClick={showCheckout}><Trans key="ls_group_coaching_cta"/></button>
                  <span class="text-xs text-gray-500"><Trans key="ls_group_coaching_vat"/></span>
                </div>
              )}</Show>
            </Show>
            <Show when={isUserSubscribed() && subscription()}>{(sub) => (
              <div class="flex flex-col gap-6">
                <div class="flex flex-col gap-2">
                  <div class="flex justify-between">
                    <div class="flex flex-col gap-2">
                      <span class="font-semibold">{sub().productPrice.name}</span>
                      <span class="text-sm text-gray-500">
                        <Trans key="ls_generic_monthly"/> - {currencyFormatter(sub().productPrice.price)}
                      </span>
                    </div>
                    <div class="h-6 flex flex-col items-end gap-2">
                      <StatusCell status={t(`ls_subscription_status_${sub().status}`)} color={SubscriptionStatusColor[sub().status]}/>
                    </div>
                  </div>
                  <Show when={sub().status !== SubscriptionStatus.Canceled}>
                    <Show when={sub().nextPaymentDate}>{(paymentDate) => (
                      <div class="flex gap-2 text-sm">
                        <span class="font-semibold"><Trans key="ls_subscription_next_payment"/></span>
                        <span>{formatDateToRegular(paymentDate())}</span>
                      </div>
                    )}</Show>
                  </Show>
                </div>
                <Show when={sub().status === SubscriptionStatus.Canceled && sub().endBillingPeriodDate}>{(expirationDate) => (
                  <div class="flex flex-col gap-6">
                    <div class="flex gap-2">
                      <span class="font-semibold text-red-500"><Trans key="ls_subscription_expires_on"/></span>
                      <span>{formatDateToRegular(expirationDate())}</span>
                    </div>
                    <div class="flex gap-4">
                      <Button action={() => { void onReactivateSubscription(sub()) }} key="ls_subscription_reactivate" style={ButtonStyle.Primary} />
                    </div>
                  </div>
                )}</Show>
                <Show when={sub().status !== SubscriptionStatus.Canceled}>
                  <div class="flex justify-between flex-col sm:flex-row gap-4">
                    <div class="flex gap-4">
                      <a href={sub().manageUrl} class={`btn ${sub().status === SubscriptionStatus.PastDue ? 'btn--primary' : 'btn--white'}`}><Trans key="ls_subscription_manage"/></a>
                    </div>
                    <button onClick={() => setIsCanceling(true)} class="text-red-500 text-sm font-semibold">
                      <Trans key="ls_subscription_cancel"/>
                    </button>
                  </div>
                </Show>
              </div>
            )}</Show>
          </Show>
        </div>
      </div>
    </>
  )
}

export default GroupCoachingSessionsCard
