import type { Component } from 'solid-js'
import { Trans } from '@mbarzda/solid-i18next'
import { Show } from 'solid-js'
import MiniTag from '../../assets/heroicons/MiniTag'
import { type SKUProductReport } from '../../features/product/domain/models/product'

export interface ViewOnEtsyCellProps {
  readonly product: SKUProductReport
}

const ProductListingCell: Component<ViewOnEtsyCellProps> = (props) => {
  const isListingDeleted = props.product.product.isListingDeleted()
  const listingTitle = isListingDeleted
    ? 'ls_listing_deleted'
    : props.product.product.listing.title

  return (
    <>
      <div class="flex gap-1">
        <Show when={false}>
          <span class="text-gray-500"><Trans key="ls_shared_multiple"/></span> -
        </Show>
        <div class={`${isListingDeleted ? 'text-gray-500' : 'text-gray-900'} mb-1 line-clamp-1`}><Trans key={listingTitle}/></div>
      </div>
      <div class="flex items-center gap-1 text-xs text-gray-500">
        <span class="min-w-4"><MiniTag size={3}/></span>
        <span class="line-clamp-1" innerHTML={props.product.product.variantName} />
      </div>
    </>
  )
}

export default ProductListingCell
