import { t } from 'i18next'
import type { Component } from 'solid-js'

export interface StatusCellProps {
  readonly color: string
  readonly status: string
}

const StatusCell: Component<StatusCellProps> = (props) => {
  return (
    <div class="flex px-2 py-1 bg-gray-100 rounded-full items-center gap-1.5 w-fit">
      <div class={`rounded-full bg-${props.color}-500 w-1.5 h-1.5`}/>
      <span class="text-xs text-gray-600">{t(props.status)}</span>
    </div>
  )
}

export default StatusCell
