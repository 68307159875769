import { Trans } from '@mbarzda/solid-i18next'
import { For, type Accessor, type Component, type Setter } from 'solid-js'
import { type KPIType, type KPIVM } from '../../../features/analytics/domain/models/kpi'
import { type KPIGroupConfig } from '../../../shared/models/kpi-group'
import Stat from './Stat'

export interface StatsGroupProps {
  readonly config: KPIGroupConfig
  readonly kpis: KPIVM[]
  readonly selectedKPI: Accessor<KPIType>
  readonly setSelectedKPI: Setter<KPIType>
  readonly color: string
  readonly direction?: 'col' | 'row'
}

const StatsGroup: Component<StatsGroupProps> = (props) => {
  const config = props.config
  const color = `bg-${props.color}-600`

  const isStatSelected = (kpi: KPIVM): boolean => props.selectedKPI() === kpi.id

  return (
    <div class="overflow-hidden bg-white shadow rounded-lg w-full">
      <li class={`${color}` + ' p-4 text-white flex gap-2 text-base font-semibold'}>
        {config.icon({})} <Trans key={`ls_kpi_group_${config.id}`}/>
      </li>
      <div class={`divide-gray-200 ${props.direction === 'row' ? 'flex divide-x' : 'divide-y'}`}>
        <For each={props.kpis}>{(kpi) => (
          <div
            onClick={() => {
              props.setSelectedKPI(kpi.id)
            }}
            class={`${isStatSelected(kpi) ? 'bg-gray-900 text-white !border-gray-900' : `${color} flex-1 bg-opacity-5 hover:bg-opacity-10 transition duration-300 text-gray-900`}` + ' p-4 cursor-pointer flex-grow'}
          >
            <Stat stat={kpi}/>
          </div>
        )}</For>
      </div>
    </div>
  )
}

export default StatsGroup
