import { Trans } from '@mbarzda/solid-i18next'
import { t } from 'i18next'
import { Show, createSignal, onMount, type Component } from 'solid-js'
import logoAnimation from '../../assets/animations/logo-animated.json'
import InformationCircleSolid from '../../assets/heroicons/InformationCircleSolid'
import MiniCheckCircle from '../../assets/heroicons/MiniCheckCircle'
import FlowerCheck from '../../assets/images/flower-check.svg'
import { MainModule } from '../../features/main-module'
import type { OfferingProduct } from '../../features/payment/domain/models/offering'
import { OneTimePurchaseType } from '../../features/payment/domain/models/one-time-purchase'
import { useUser } from '../../public/auth/user-provider'
import { useNumberFormatter } from '../../shared/helpers/currency-formatter'
import { useTracking } from '../../shared/providers/tracking.provider'
import LottieAnimation from '../shared/components/LottieAnimation'
import StatusCell from '../shared/components/StatusCell'

const AllInEtsyMasterclassCard: Component = () => {
  const [isLoading, setIsLoading] = createSignal(true)
  const [offering, setOffering] = createSignal<OfferingProduct | undefined>(undefined)

  const { currentUser, currentSubscription, setUserPurchases, userPurchases } = useUser()
  const { currencyFormatter } = useNumberFormatter()
  const { trackEvent, trackUser } = useTracking()

  const purchaseProduct = MainModule.getPaymentComponent().providePurchaseProduct()
  const getPurchases = MainModule.getPaymentComponent().provideGetPurchases()

  onMount(async (): Promise<void> => {
    const offeringType = 'course'

    const getOffering = MainModule.getPaymentComponent().provideGetOffering()
    const offer = await getOffering.execute(offeringType)

    setOffering(offer.offerings.find(offering => offering.type === OneTimePurchaseType.CourseMasterclassFullPrice)?.products[0])
    setIsLoading(false)
  })

  const refreshPurchases = async (): Promise<void> => {
    const purchases = await getPurchases.execute()
    setUserPurchases(purchases)

    if (!userPurchases()?.hasAllInMasterclass) {
      setTimeout(async () => { await refreshPurchases() }, 2000)
      return
    }

    trackUser(currentUser()!, userPurchases())
    setIsLoading(false)
  }

  const purchase = async (): Promise<void> => {
    setIsLoading(true)
    trackEvent('Purchase', { product: 'Etsy Masterclass' })
    await purchaseProduct.execute(currentSubscription()!.id, offering()!.id)
    await refreshPurchases()
  }

  return (
    <div class="rounded-lg flex flex-col w-full 2xl:w-full max-w-[572px] bg-white overflow-hidden h-fit">
      <div class="w-full aspect-[1144/420] bg-gray-200">
        <img src='/images/masterclass-header.png'/>
      </div>
      <div class="flex flex-col gap-6 p-6 pt-4">
        <div class="flex flex-col gap-2">
          <div class="flex gap-4 justify-between">
            <span class="text-sm text-orange-500 font-bold"><Trans key="ls_masterclass_course" /></span>
            <Show when={userPurchases()?.hasAllInMasterclass}>
              <StatusCell color="green" status="Purchased" />
            </Show>
          </div>
          <span class="text-2xl font-bold"><Trans key="ls_masterclass_title" /></span>
          <span class="text-sm"><Trans key="ls_masterclass_description" /></span>
        </div>
        <Show when={!isLoading()} fallback={
          <div class="w-full h-[140px] rounded-lg bg-blue_gray-100 flex items-center justify-center">
            <LottieAnimation animationData={logoAnimation} width="2rem"/>
          </div>
        }>
          <Show when={!userPurchases()?.hasAllInMasterclass}>
            <div class="flex flex-col gap-2">
              <span class="text-xs text-gray-500 font-bold"><Trans key="ls_masterclass_included" /></span>
              <div class="flex gap-2">
                <span class="text-green-500 min-w-[20px]"><img class="text-green-500 m-0.5" src={FlowerCheck}/></span>
                <div class="flex grow justify-between flex-col md:flex-row">
                  <span class="text-sm font-semibold grow"><Trans key="ls_masterclass_feature1" /></span>
                  <span class="text-sm text-gray-500">
                    Normally {currencyFormatter(799, 0)}
                  </span>
                </div>
              </div>
              <div class="h-px bg-gray-200 w-full" />
              <div class="flex gap-2">
                <span class="text-green-500"><MiniCheckCircle/></span>
                <div class="flex grow justify-between flex-col md:flex-row">
                  <span class="text-sm font-semibold grow"><Trans key="ls_masterclass_feature2" /></span>
                  <span class="text-sm text-gray-500">
                    <Trans key="ls_masterclass_normally" options={{ price: currencyFormatter(649, 0) }} />
                  </span>
                </div>
              </div>
              <div class="flex gap-2">
                <span class="text-green-500"><MiniCheckCircle/></span>
                <div class="flex grow justify-between flex-col md:flex-row">
                  <span class="text-sm font-semibold grow"><Trans key="ls_masterclass_feature3" /></span>
                  <span class="text-sm text-gray-500">
                    <Trans key="ls_masterclass_normally" options={{ price: currencyFormatter(399, 0) }} />
                  </span>
                </div>
              </div>
              <div class="flex gap-2">
                <span class="text-green-500"><MiniCheckCircle/></span>
                <div class="flex grow justify-between flex-col md:flex-row">
                  <span class="text-sm font-semibold grow"><Trans key="ls_masterclass_feature4" /></span>
                  <span class="text-sm text-gray-500">
                    <Trans key="ls_masterclass_normally" options={{ price: currencyFormatter(237, 0) }} />
                  </span>
                </div>
              </div>
              <div class="flex gap-2">
                <span class="text-green-500"><MiniCheckCircle/></span>
                <span class="text-sm font-semibold grow"><Trans key="ls_masterclass_feature5" /></span>
              </div>
              <div class="flex gap-2">
                <span class="text-green-500"><MiniCheckCircle/></span>
                <span class="text-sm font-semibold grow"><Trans key="ls_masterclass_feature6" /></span>
              </div>
            </div>
            <div class="h-px bg-gray-200 w-full" />
            <div class="flex flex-col gap-4">
              <div class="flex flex-col gap-2">
                <span class="text-sm font-semibold">
                  <Trans key="ls_masterclass_special_offer" />
                </span>
                <div class="text-sm flex gap-2 font-bold">
                  <span class="text-gray-400 line-through text-4xl">{currencyFormatter(2084, 0)}</span>
                  <span class="text-4xl">{currencyFormatter(offering()?.price, 0)}</span>
                </div>
              </div>
              <button class="btn btn--primary !w-full" onClick={purchase}>
                <Trans key="ls_shared_buy_now" />
              </button>
              <span class="text-xs text-gray-500"><Trans key="ls_masterclass_vat" /></span>
            </div>
          </Show>
          <Show when={userPurchases()?.hasAllInMasterclass}>
            <div class="flex gap-4 p-4 bg-blue_gray-50 border-blue_gray-300 border rounded-xl">
              <span class="min-w-6 text-blue_gray-500">
                <InformationCircleSolid />
              </span>
              <span class="text-sm" innerHTML={t('ls_masterclass_access')} />
            </div>
          </Show>
        </Show>
      </div>
    </div>
  )
}

export default AllInEtsyMasterclassCard
