import { Trans } from '@mbarzda/solid-i18next'
import { A } from '@solidjs/router'
import { t } from 'i18next'
import { Show, type Component } from 'solid-js'
import { AppRoutes } from '../../shared/app-routes'

export interface MainMissingCogsBannerProps {
  readonly missingCogs: number
}

const MainMissingCogsBanner: Component<MainMissingCogsBannerProps> = (props) => {
  return (
    <Show when={props.missingCogs}>{(missingCogs) => (
      <Show when={missingCogs() > 0}>
        <div class="text-white relative flex gap-4 items-center bg-red-600 justify-between min-h-[80px] flex-col xl:flex-row rounded-lg mb-6 px-8 xl:px-4 py-4 xl:py-0">
          <img src='/images/bust.svg' class="absolute hidden sm:block sm:w-36 xl:w-[104px] sm:top-4 sm:left-2 xl:top-auto xl:bottom-0 xl:left-4"/>
          <div class="flex flex-col xl:flex-row xl:items-center sm:pl-[136px] xl:pl-[114px] gap-4 w-full">
            <div class="flex flex-col gap-1 justify-center w-full">
              <p class="text-xl m-0" innerHTML={t('ls_cogs_num_without_cogs', { num: missingCogs() })} />
              <p class="text-sm m-0"><Trans key="ls_cogs_we_can"/></p>
            </div>
            <A href={AppRoutes.CogsIssues()} class="w-full xl:w-min whitespace-nowrap flex items-center justify-center rounded-md !border-gray-300 cursor-pointer !bg-white px-3.5 py-2 text-sm font-semibold text-gray-800 h-fit shadow-sm hover:!bg-gray-200 transition duration-200">
              <Trans key="ls_cogs_fix_now"/>
            </A>
          </div>
        </div>
      </Show>
    )}</Show>
  )
}

export default MainMissingCogsBanner
