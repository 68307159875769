import { type Component, Show } from 'solid-js'
import { useUser } from '../../../public/auth/user-provider'
import Dialog from './Dialog/Dialog'
import DialogActions from './Dialog/DialogActions'
import Button, { ButtonStyle } from './Button'
import { OfferingType } from '../../../features/payment/domain/models/offering'
import { t } from 'i18next'
import { Trans } from '@mbarzda/solid-i18next'
import { type Subscription } from '../../../features/payment/domain/models/subscription'
import ExclamationTriangle from '../../../assets/heroicons/ExclamationTriangle'

export interface CancelSubscriptionDialogProps {
  readonly subscription: Subscription
  readonly close: () => void
  readonly cancel: () => void
}

const CancelSubscriptionDialog: Component<CancelSubscriptionDialogProps> = (props) => {
  const { userPurchases } = useUser()

  const getSubscriptionType = (): OfferingType => {
    return props.subscription.productPrice.offeringType as OfferingType
  }

  const isCancelingProAsLifetime = (): boolean => {
    return getSubscriptionType() !== OfferingType.GroupCoaching && (userPurchases()?.isLifetime ?? false)
  }

  const isCancelingWithoutLifetime = (): boolean => {
    return getSubscriptionType() !== OfferingType.GroupCoaching && (!userPurchases()?.isLifetime)
  }

  return (
    <Dialog maxWidth={'606px'}>
      <div class="flex gap-4 p-6">
        <span class="text-red-600 flex items-center justify-center min-w-[40px] h-10 rounded-full bg-red-100">
          <ExclamationTriangle/>
        </span>
        <div class="flex flex-col gap-2 text-sm text-gray-500">
          <span class="text-base text-gray-900 font-bold">
            <Trans key="ls_subscription_cancel_title"/>
          </span>
          <Show when={getSubscriptionType() === OfferingType.GroupCoaching}>
            <span innerHTML={t('ls_subscription_cancel_group_coaching')} />
          </Show>
          <Show when={isCancelingProAsLifetime()}>
            <span innerHTML={t('ls_subscription_cancel_pro_lifetime')} />
          </Show>
          <Show when={isCancelingWithoutLifetime()}>
            <span innerHTML={t('ls_subscription_cancel_plan')} />
          </Show>
        </div>
      </div>
      <DialogActions>
        <Button action={props.close} key="ls_subscription_keep_subscribed" style={ButtonStyle.White}/>
        <Button action={props.cancel} key="ls_subscription_cancel" style={ButtonStyle.Red}/>
      </DialogActions>
    </Dialog>
  )
}

export default CancelSubscriptionDialog
