import { type SubscriptionStatus } from './subscription'

export enum OneTimePurchaseType {
  Lifetime = 'profittree_lifetime',
  UnlimitedShops = 'unlimited_shops',
  CourseMasterclass = 'course_masterclass',
  CourseMasterclassFullPrice = 'course_masterclass_fp'
}

export class OneTimePurchase {
  constructor(
    public readonly id: string,
    public readonly billedDate: Date,
    public readonly productPrice: PurchaseProduct,
    public readonly status: SubscriptionStatus
  ) {
  }
}

export class PurchaseProduct {
  constructor(
    public readonly id: string,
    public readonly price: number,
    public readonly currencyCode: string,
    public readonly offeringType: OneTimePurchaseType,
    public readonly name: string
  ) {
  }
}
