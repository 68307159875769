import { type Component } from 'solid-js'
import SectionCard from './SectionCard'
import { Trans } from '@mbarzda/solid-i18next'
import Sparkles from '../../assets/heroicons/Sparkles'
import MiniEtsy from '../../assets/heroicons/MiniEtsy'
import { t } from 'i18next'
import ShoppingBagSolid from '../../assets/heroicons/ShoppingBagSolid'
import RectangleStackSolid from '../../assets/heroicons/RectangleStackSolid'
import CalendarDaysSolid from '../../assets/heroicons/CalendarDaysSolid'
import { A } from '@solidjs/router'
import { AppRoutes } from '../../shared/app-routes'

const UpgradePlanCard: Component = () => {
  return (
    <SectionCard styleNumColumns='half'>
      <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-2">
          <div class="flex gap-2 font-semibold items-center">
            <span class="text-japanese-600"><Sparkles /></span><Trans key={'ls_upgrade_title'}/>
          </div>
          <span class="text-sm"><Trans key="ls_upgrade_description" /></span>
        </div>
        <div class="flex flex-col gap-4 text-sm">
          <div class="flex gap-2 items-center">
            <span class="text-japanese-600"><MiniEtsy size={5} /></span><span innerHTML={t('ls_upgrade_feature_1')} />
          </div>
          <div class="flex gap-2 items-center">
            <span class="text-japanese-600"><CalendarDaysSolid size={5} /></span><span innerHTML={t('ls_upgrade_feature_2')} />
          </div>
          <div class="flex gap-2 items-center">
            <span class="text-japanese-600"><ShoppingBagSolid size={5} /></span><span innerHTML={t('ls_upgrade_feature_3')} />
          </div>
          <div class="flex gap-2 items-center">
            <span class="text-japanese-600"><RectangleStackSolid size={5} /></span><span innerHTML={t('ls_upgrade_feature_4')} />
          </div>
        </div>
        <A href={AppRoutes.Subscription()} class="btn btn--primary"><Trans key="ls_upgrade_action"/></A>
      </div>
    </SectionCard>
  )
}

export default UpgradePlanCard
