import { type Component } from 'solid-js'
import Logo from '../assets/images/profittree-bnw-logo.svg'
import { Trans } from '@mbarzda/solid-i18next'

const Footer: Component = () => {
  return (
    <div class="flex flex-col text-center sm:flex-row sm:text-left items-center justify-center gap-8 border-t border-gray-200 bg-blue_gray-100 p-8">
      <div class="min-w-[224px] flex justify-center items-center">
        <img src={Logo} alt="Profittree" class="h-6" />
      </div>
      <div class="text-sm text-gray-500">
        <p><Trans key="ls_footer_line1" /></p>
        <div>
          <Trans key="ls_footer_line2"/>&nbsp;
          <div class="sm:hidden h-4" />
          <a href="https://profittree.io/terms-and-conditions" class="underline">
            <Trans key="ls_footer_terms_and_conditions"/>
          </a> | <a href="https://profittree.io/privacy-policy" class="underline">
            <Trans key="ls_footer_privacy_policy"/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
